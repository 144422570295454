import { Tooltip } from "components/Common/Tooltip/Tooltip";
import { HelpCircle } from "./Icons";
import styles from "./helpTooltip.module.scss";
import classnames from "classnames/bind";

const classNameBuilder = classnames.bind(styles);

export const HelpTooltip = ({
  className,
  iconClassName,
  children,
  width,
  ...rest
}) => {
  return (
    <div className={classNameBuilder("container", className)}>
      <HelpCircle className={iconClassName} />
      <Tooltip style={width ? { width } : undefined} {...rest}>
        {children}
      </Tooltip>
    </div>
  );
};
