import React, { useState } from "react";
import { useTheme } from "../../contexts/ThemeContext";
import styles from "./button.module.scss";

import classnames from "classnames/bind";
import { noop } from "lodash";
import { KEYS } from "../../utils/listeners";

const classNameBuilder = classnames.bind(styles);

export const Button = ({
  className,
  children,
  dark,
  square,
  selected,
  active,
  onMouseDown: onMouseDownProp = noop,
  onMouseUp: onMouseUpProp = noop,
  onMouseLeave: onMouseLeaveProp = noop,
  rotation,
  icon,
  round,
  fitContent,
  colorful,
  spaceDisabled,
  disableMovement,
  disableHover,
  hideBackground,
  transparentBackground,
  large,
  fullWidth,
  ...rest
}) => {
  const { themeClassNames } = useTheme();
  const [clicked, setClicked] = useState(false);
  const onMouseDown = (e) => {
    setClicked(true);
    onMouseDownProp(e);
  };
  const onMouseUp = (e) => {
    setClicked(false);
    onMouseUpProp(e);
  };
  const onMouseLeave = (e) => {
    setClicked(false);
    onMouseLeaveProp(e);
  };
  return (
    <button
      className={classNameBuilder("button", ...themeClassNames, className, {
        dark,
        square,
        selected,
        active,
        clicked,
        rotation,
        icon,
        colorful,
        round,
        "enable-movement": !disableMovement,
        "fit-content": fitContent,
        "enable-hover": !disableHover,
        large,
        "full-width": fullWidth,
      })}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      onMouseLeave={onMouseLeave}
      onKeyUp={(e) => {
        if (spaceDisabled && e.code === KEYS.SPACE) {
          e.preventDefault();
        }
      }}
      {...rest}
    >
      {children}
      {!hideBackground && (
        <div
          className={classNameBuilder("background", ...themeClassNames, {
            dark,
            square,
            selected,
            active,
            clicked,
            "fit-content": fitContent,
            "transparent-background": transparentBackground,
          })}
        />
      )}
    </button>
  );
};
