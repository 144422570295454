import { createRoute } from "@tanstack/react-router";
import { rootRoute } from "components/Router/routes/rootRoute";
import { shipRouteAppHeaderConfig } from "./shipRouteAppHeaderConfig";

export const shipRoute = createRoute({
  getParentRoute: () => rootRoute,
  ...shipRouteAppHeaderConfig,
}).lazy(() => import("./lazyShipsRoutes").then((d) => d.shipRoute));

const specificShipRoute = createRoute({
  getParentRoute: () => shipRoute,
  path: "$pageId",
}).lazy(() => import("./lazyShipsRoutes").then((d) => d.specificShipRoute));

shipRoute.addChildren([specificShipRoute]);
