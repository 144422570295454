import React, { useState, useLayoutEffect } from "react";
import { Button } from "components/Common/Button";
import { NEUTRAL } from "constants/themes";
import { Tooltip } from "components/Common/Tooltip/Tooltip";
import styles from "./tutorialTooltip.module.scss";
import { TOOLTIP_SIZES } from "contexts/TooltipStyleContext";

const getTooltipProps = (sourceRef) => ({
  sourceRef,
  showing: true,
  tailHeight: 12,
  offset: 6,
  interactive: true,
  style: {
    borderColor: "$light-gray-2",
  },
  theme: NEUTRAL,
  position: "bottom",
});

export const TutorialTooltip = ({
  sourceId,
  position,
  width,
  skippable,
  hideNext,
  skipLabel = "SKIP STEP",
  nextLabel = "NEXT",
  children,
  onButtonClick,
  onSkipToEnd,
  shouldRender,
  shouldRenderProps,
  ...rest
}) => {
  const [tooltipSourceRef, setTooltipSourceRef] = useState(null);
  useLayoutEffect(() => {
    if (shouldRender) {
      let el = null;
      let raf = null;
      const setEl = () => {
        el = document.getElementById(sourceId);
        if (!el) {
          raf = window.requestAnimationFrame(setEl);
        } else {
          setTooltipSourceRef(el);
        }
        return el;
      };
      const firstEl = setEl();
      if (!firstEl) {
        setTooltipSourceRef(null);
      }
      return () => window.cancelAnimationFrame(raf);
    } else if (!shouldRender) {
      tooltipSourceRef && setTooltipSourceRef(null);
    }
  }, [
    sourceId,
    shouldRender,
    tooltipSourceRef,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    ...Object.values(shouldRenderProps),
  ]);

  if (!shouldRender) return null;

  return (
    tooltipSourceRef && (
      <Tooltip
        {...getTooltipProps(tooltipSourceRef)}
        position={position}
        size={TOOLTIP_SIZES.UNRESTRICTED}
        {...rest}
      >
        <div className={styles["tooltip"]} style={{ width }}>
          {children}
          {!hideNext && (
            <div className={styles["footer"]}>
              {onSkipToEnd && (
                <Button
                  className={styles["button"]}
                  large
                  onClick={onSkipToEnd}
                >
                  SKIP TUTORIAL
                </Button>
              )}
              <div className={styles["spacer"]} />
              <Button
                className={styles["button"]}
                colorful={!skippable}
                large
                onClick={onButtonClick}
              >
                {skippable ? skipLabel : nextLabel}
              </Button>
            </div>
          )}
        </div>
      </Tooltip>
    )
  );
};
