import { getRandIntExclusive } from "lib/numberUtils";
import {
  BookCheck,
  BookHeart,
  Book as BookIcon,
  BookImage,
  BookMarked,
  BookText,
  BookUser,
} from "lucide-react";
import { useEffect, useState } from "react";

export const BOOKS_LIST = [
  <BookIcon />,
  <BookUser />,
  <BookImage />,
  <BookHeart />,
  <BookText />,
  <BookCheck />,
  <BookMarked />,
];
export const Book = ({
  className,
  value: valueProp = 0,
  rollInterval = 200,
  hovered,
  animated,
}) => {
  const [value, setValue] = useState(valueProp);
  useEffect(() => {
    if (hovered || animated) {
      let prevValue = value;
      const id = window.setInterval(() => {
        let newValue;
        do {
          newValue = getRandIntExclusive(BOOKS_LIST.length, 0);
        } while (newValue === prevValue);
        prevValue = newValue;
        setValue(newValue);
      }, rollInterval);
      return () => window.clearInterval(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hovered, animated, rollInterval]);
  return BOOKS_LIST[value];
};
