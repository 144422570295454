import { Fragment } from "react";

export const convertArrToSeparatedDiv = (strings, separator = null) => (
  <div>
    {strings.map((s, i) =>
      i !== strings.length - 1 ? (
        <Fragment key={i}>
          <div>{s}</div>
          {separator}
        </Fragment>
      ) : (
        <div key={i}>{s}</div>
      )
    )}
  </div>
);
export const convertArrToSpacedDiv = (strings) =>
  convertArrToSeparatedDiv(strings, <br />);
