import React, { useEffect, useMemo, useRef, useState } from "react";
import { useAuth } from "contexts/AuthContext";
import { useTutorial } from "contexts/database/TutorialContext";
import { TUTORIAL_MAP } from "./consts/tutorialMap";
import { usePortal } from "contexts/PortalContext";
import { useIFrame } from "contexts/IframeContext";
import { isMobile } from "react-device-detect";
import { TUTORIALS } from "./consts/tutorialConsts";
import { useAppHeader } from "contexts/AppHeaderContext";
import { usePage } from "hooks/usePage";

export const Tutorial = () => {
  const { user } = useAuth();
  const {
    settingsDrawer: {
      portalProps: {
        isPortalComponentRendered: isSettingsDrawerOpen,
        setIsPortalComponentRendered: setSettingsDrawerOpen,
      },
    },
  } = useAppHeader();
  const portalProps = usePortal();
  const { setIsLockedPortalComponentRendered } = portalProps;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [renderedTutorialId, setRenderedTutorialId] = useState(null);
  const prevTutorialIdRef = useRef(null);
  const {
    tutorialStatusesReady,
    getTutorialStatus,
    incrementTutorialStatus,
    updateTutorial,
    decrementTutorialStatus,
    completeTutorial,
  } = useTutorial();
  const { isIFrame } = useIFrame();

  // TODO: future tutorials can have more complex triggers, will need to
  // refactor how tutorials are "active" if we get to that point
  const currTutorialId = useMemo(
    () =>
      tutorialStatusesReady &&
      Object.values(TUTORIALS).find((id) => getTutorialStatus(id) > -1),
    [tutorialStatusesReady, getTutorialStatus]
  );
  const [page] = usePage();

  useEffect(() => {
    if (currTutorialId !== prevTutorialIdRef.current) {
      setRenderedTutorialId(currTutorialId);
      prevTutorialIdRef.current = currTutorialId;
      if (isSettingsDrawerOpen) {
        setSettingsDrawerOpen(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currTutorialId]);
  useEffect(() => {
    setIsLockedPortalComponentRendered(isModalOpen);
  }, [isModalOpen, setIsLockedPortalComponentRendered]);
  useEffect(() => {
    if (!renderedTutorialId && isModalOpen) {
      setIsModalOpen(false);
    }
  }, [renderedTutorialId, isModalOpen]);
  useEffect(() => {
    if (renderedTutorialId && isModalOpen && isSettingsDrawerOpen) {
      setSettingsDrawerOpen(false, true);
    }
  }, [
    renderedTutorialId,
    isModalOpen,
    isSettingsDrawerOpen,
    setSettingsDrawerOpen,
  ]);
  const props = useMemo(
    () =>
      renderedTutorialId && {
        status: getTutorialStatus(renderedTutorialId),
        incrementStatus: () => incrementTutorialStatus(renderedTutorialId),
        decrementStatus: () => decrementTutorialStatus(renderedTutorialId),
        updateStatus: (val) => updateTutorial(renderedTutorialId, val),
        complete: () => completeTutorial(renderedTutorialId),
      },
    [
      renderedTutorialId,
      getTutorialStatus,
      incrementTutorialStatus,
      decrementTutorialStatus,
      updateTutorial,
      completeTutorial,
    ]
  );

  // no tutorials while on join group page
  if (
    !user ||
    !renderedTutorialId ||
    isIFrame ||
    isMobile ||
    page === "join-group"
  ) {
    return null;
  }
  const Component = TUTORIAL_MAP[renderedTutorialId].Component;

  return (
    <Component
      portalProps={portalProps}
      {...props}
      setIsModalOpen={setIsModalOpen}
    />
  );
};
