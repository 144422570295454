import { useConfirmationModal } from "components/Common/Portals/ConfirmationModal/ConfirmationModal";
import { useManagedPortal } from "components/Common/Portals/hooks/useManagedPortal";
import { useCallback, useRef } from "react";

export const useSettingsDrawerPortal = () => {
  const shouldConfirmCloseRef = useRef();
  const {
    portalProps: confirmPortalProps,
    openModal: openConfirmModal,
    confirmationModalController,
  } = useConfirmationModal();

  const onInterceptPortalClose = useCallback(
    async ({ portalLength }) => {
      // if a portal inside the settings drawer is open, allow the user to close that modal
      if (portalLength !== 1) {
        return true;
      }

      // if the confirmation modal should be shown, then use the confirmation modal
      //  to determine if the drawer should close
      return shouldConfirmCloseRef.current ? openConfirmModal() : true;
    },
    [openConfirmModal]
  );

  const portalProps = useManagedPortal({
    onInterceptPortalClose,
  });

  return {
    portalProps,
    confirmationModalController,
    confirmPortalProps,
    openConfirmModal,
    shouldConfirmCloseRef,
  };
};
