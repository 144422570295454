export const Patreon = (props) => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M24 48C37.2548 48 48 37.2548 48 24C48 10.7452 37.2548 0 24 0C10.7452 0 0 10.7452 0 24C0 37.2548 10.7452 48 24 48Z"
      fill="#F76754"
    />
    <path d="M12.8 34.4H16.8V13.6H12.8V34.4Z" fill="#002C49" />
    <path
      d="M27.2125 13.6C31.6168 13.6 35.2 17.194 35.2 21.612C35.2 26.0166 31.6168 29.6 27.2125 29.6C22.7944 29.6 19.2 26.0166 19.2 21.612C19.2 17.194 22.7944 13.6 27.2125 13.6Z"
      fill="white"
    />
  </svg>
);
