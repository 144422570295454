import { Section } from "components/Common/Section";
import {
  BOOLEAN_CONFIGS_BY_CATEGORY,
  SETTINGS_DRAWER_BOOLEAN_CATEGORIES_CONFIG,
  SETTINGS_DRAWER_BOOLEAN_DEFAULT_VALUE,
} from "../useSettingsDrawerBooleans";
import settingsStyles from "../settingsDrawer.module.scss";
import { get, identity } from "lodash";
import { fpUpdate } from "lib/fpLodash";
import { SettingsDrawerToggle } from "./SettingsDrawerToggle";
import React, { Fragment } from "react";

export const BooleanConfigSection = ({ category, tempUser, setTempUser }) => {
  const configs = BOOLEAN_CONFIGS_BY_CATEGORY[category];

  if (!configs) {
    return null;
  }

  return (
    <Section
      label={
        SETTINGS_DRAWER_BOOLEAN_CATEGORIES_CONFIG?.[category]?.label || category
      }
      className={settingsStyles["entry"]}
      contentClassName={settingsStyles["entry-content"]}
      isSubSection
    >
      {configs.map((config, i) => {
        const {
          key,
          getToggleValue = identity,
          subCategoryLabel,
          defaultValue = SETTINGS_DRAWER_BOOLEAN_DEFAULT_VALUE,
          onUpdate,
          onText = "Enabled",
          offText = "Disabled",
          ...restConfig
        } = config;

        const updater =
          onUpdate || fpUpdate(key, (prev) => !(prev ?? defaultValue));
        const value = get(tempUser, key) ?? defaultValue;

        return (
          <Fragment key={i}>
            {subCategoryLabel && (
              <div className={settingsStyles["sub-label"]}>
                {subCategoryLabel}
              </div>
            )}
            <SettingsDrawerToggle
              key={key}
              value={getToggleValue(value)}
              onToggle={() => setTempUser(updater)}
              onText={onText}
              offText={offText}
              {...restConfig}
            />
          </Fragment>
        );
      })}
    </Section>
  );
};
