import { useLocation } from "@tanstack/react-router";
import { useEffect } from "react";

export const useForceScrollToTop = ({
  scrollRef,
  behavior,
  disabled,
  deps = [],
}) => {
  const { pathname } = useLocation();

  useEffect(() => {
    if (disabled || scrollRef?.current) {
      scrollRef.current.scrollTo({ top: 0, left: 0, behavior });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollRef, pathname, behavior, disabled, ...deps]);
};
