import React from "react";
import { TooltipPortal } from "react-mint";
import { NEUTRAL, THEMES } from "../constants/themes";
import { useTheme } from "./ThemeContext";
import themes from "styles/themes.scss";

export const TooltipProvider = ({ className, children }) => {
  const { theme, themeClassNames } = useTheme();

  const SHARED_PROPS = {
    delay: 300,
    tailHeight: 0,
    autoFlip: false,
    className: themeClassNames.join(" "),
  };

  return (
    <TooltipPortal
      className={className}
      defaultTheme={theme.primary || THEMES.PRIMARY.DARK}
      themes={{
        [THEMES.PRIMARY.DARK]: {
          ...SHARED_PROPS,
        },
        [THEMES.PRIMARY.LIGHT]: {
          ...SHARED_PROPS,
        },
        [NEUTRAL]: {
          ...SHARED_PROPS,
          style: {
            ...SHARED_PROPS.style,
            border: `solid 2px ${themes["light-gray"]}`,
          },
        },
      }}
    >
      {children}
    </TooltipPortal>
  );
};
