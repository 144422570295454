import styles from "./link.module.scss";
import classnames from "classnames/bind";
import { useNavigator } from "components/Router/NavigatorContext";
import { noop } from "lodash";
import { Link as ExternalLink } from "@tanstack/react-router";

const classNameBuilder = classnames.bind(styles);

export const Link = ({
  to,
  replace,
  params,
  options,
  className,
  children,
  onClick = noop,
  navigateOverride,
  ...rest
}) => {
  const { navigate } = useNavigator();

  return (
    <ExternalLink
      className={classNameBuilder("container", className)}
      onClick={(e) => {
        onClick(e);
        if (e.metaKey || e.ctrlKey) {
          return;
        }
        e.preventDefault();
        const nav = navigateOverride || navigate;
        nav({ to, replace, params, ...options });
      }}
      to={to}
      params={params}
      {...rest}
    >
      {children}
    </ExternalLink>
  );
};
