import { useLayoutEffect } from "react";
import { fpSet, fpGet, fpForEach, fpUnset } from "lib/fpLodash";
import { uid } from "../../lib/uid";

let listeners = {};

const registerListener = (target, key) => {
  target.addEventListener(key, (e) => {
    forEachListener((callback) => {
      callback(e);
    }, key);
  });
  return (callback, deps) => {
    useListener(callback, deps, key);
  };
};

const addListener = (callback, ...path) => {
  const id = uid();
  if (!fpGet(path, listeners)) {
    listeners = fpSet(path, {}, listeners);
  }
  listeners = fpSet([...path, id], callback, listeners);
  return id;
};

const removeListener = (...path) => {
  listeners = fpUnset(path, listeners);
};

const useListener = (callback, deps = [], ...path) => {
  useLayoutEffect(() => {
    const id = addListener(callback, ...path);
    return () => {
      removeListener(...path, id);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
};

const forEachListener = (callback, ...path) => {
  fpForEach(callback, fpGet(path, listeners));
};

export {
  listeners,
  registerListener,
  addListener,
  removeListener,
  forEachListener,
  useListener,
};
