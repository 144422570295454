import { useCallback, useMemo } from "react";
import { useAuth } from "../contexts/AuthContext";
import { useGroupsAccessors } from "../contexts/database/useGroupsAccessors";
import { useDocumentGroup } from "./useDocumentGroup";
import { WHERE_OPERATORS } from "../contexts/database/useMainAccessors";

export const GROUPS_QUERY_KEY = "groups";
export const GROUP_FETCH_TARGET = {
  ALL: "ALL",
  ALL_USER: "ALL_USER",
  RECENT: "RECENT",
};

export const useGroups = ({ shouldShowAllUserGroups, ...options } = {}) => {
  const { user } = useAuth();
  const groupAccessors = useGroupsAccessors();

  const targetWhere = useMemo(() => {
    if (!shouldShowAllUserGroups && user?.recentlyUsedGroups) {
      return null;
    }

    return {
      key: "users",
      operator: WHERE_OPERATORS.ARRAY_CONTAINS,
      value: user.uid || "",
    };
  }, [shouldShowAllUserGroups, user?.recentlyUsedGroups, user.uid]);

  const targetIdsFallback = useCallback(() => {
    return groupAccessors.getWhere(
      "users",
      WHERE_OPERATORS.ARRAY_CONTAINS,
      user.uid || ""
    );
  }, [groupAccessors, user.uid]);

  const {
    data: groups,
    dataMap: groupsMap,
    isFetching: fetchingGroups,
    refetch: refetchGroups,
    error: groupsError,
    ...rest
  } = useDocumentGroup({
    accessors: groupAccessors,
    targetIds: shouldShowAllUserGroups ? undefined : user?.recentlyUsedGroups,
    targetIdsFallback,
    shouldFallbackToAll: true,
    targetWhere: targetWhere,
    queryKey: GROUPS_QUERY_KEY,
    ...options,
  });

  return {
    groups,
    groupsMap,
    groupsError,
    fetchingGroups,
    refetchGroups,
    ...rest,
  };
};
