import {
  CHARACTER_WIZARD_PATH,
  CHARACTER_WIZARD_QUICKSTART_PATH,
} from "./charactersConsts";
import styles from "./charactersRoutes.module.scss";
import { Outlet, createRoute } from "@tanstack/react-router";
import { rootRoute } from "components/Router/routes/rootRoute";
import { characterRouteAppHeaderConfig } from "./characterRouteAppHeaderConfig";

const OutletForwarder = (props) => {
  return <Outlet {...props} />;
};

export const characterRoute = createRoute({
  getParentRoute: () => rootRoute,
  ...characterRouteAppHeaderConfig,
}).lazy(() => import("./lazyCharactersRoutes").then((d) => d.characterRoute));

const specificCharacterRoute = createRoute({
  getParentRoute: () => characterRoute,
  path: "$pageId",
  staticData: {
    label: "Character",
  },
}).lazy(() =>
  import("./lazyCharactersRoutes").then((d) => d.specificCharacterRoute)
);

export const characterWizardRoute = createRoute({
  getParentRoute: () => characterRoute,
  path: `${CHARACTER_WIZARD_PATH}`,
  component: OutletForwarder,
  staticData: {
    label: "Character Wizard",
    shouldHideHeaderPortal: true,
    shouldHideFooter: true,
    appClassName: styles["app"],
    contentClassName: styles["content"],
  },
});

const quickstartCharacterWizardRoute = createRoute({
  getParentRoute: () => characterWizardRoute,
  path: CHARACTER_WIZARD_QUICKSTART_PATH,
  staticData: {
    label: "Quickstart Character Wizard",
  },
}).lazy(() =>
  import("./lazyCharactersRoutes").then((d) => d.quickstartCharacterWizardRoute)
);

characterWizardRoute.addChildren([quickstartCharacterWizardRoute]);
characterRoute.addChildren([characterWizardRoute, specificCharacterRoute]);
