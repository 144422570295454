import { EmptyPageSection } from "components/Common/Section/EmptyPageSection";
import styles from "./setupUser.module.scss";
import { useEffect, useState } from "react";
import {
  DEFAULT_PORTRAIT,
  Portrait,
} from "components/Common/Portrait/Portrait";
import { usePortal } from "contexts/PortalContext";
import { Input } from "components/Common/Input";
import { Button } from "components/Common/Button";
import { useAuth } from "contexts/AuthContext";
import { RADIO_STATES, Radio } from "components/Common/Radio";
import { useTheme } from "contexts/ThemeContext";
import {
  THEMES,
  secondaryThemesList,
  secondaryThemesMap,
} from "constants/themes";
import { Dropdown } from "components/Common/Dropdown";

export const SetupUser = () => {
  const { user: initialUser, setShouldSetupUser, updateUser } = useAuth();
  const [photoURL, setPhotoURL] = useState(
    initialUser?.photoURL || DEFAULT_PORTRAIT
  );
  const [displayName, setDisplayName] = useState(
    initialUser?.displayName || ""
  );
  const { theme, setPrimaryTheme, setSecondaryTheme } = useTheme();
  const { primary } = theme;

  // in case the user somehow updates, make the state match
  useEffect(() => {
    setPhotoURL(initialUser?.photoURL);
    setDisplayName(initialUser?.displayName);
  }, [initialUser]);

  const photoPortalProps = usePortal();

  return (
    <EmptyPageSection
      icon={null}
      label={"Customize your Profile"}
      contentContainerClassName={styles["content-container"]}
      textContainerClassName={styles["content"]}
    >
      <div className={styles["form"]}>
        <Portrait
          value={photoURL}
          className={styles["portrait"]}
          portalProps={photoPortalProps}
          shouldRenderPreview
          portalComponentProps={{
            onSubmit: (newPhoto) => setPhotoURL(newPhoto),
          }}
          forceHoverButtons={true}
        />
        <div className={styles["data"]}>
          <div className={styles["entry"]}>
            <u>Display Name:</u>
            <Input
              value={displayName || ""}
              onChange={(e) => setDisplayName(e.target.value || "")}
              placeholder={"Char Smith"}
            />
          </div>
          <div className={styles["entry"]}>
            <u>Primary Theme:</u>
            <div className={styles["radio-set"]}>
              <Radio
                label={"Dark"}
                value={primary === THEMES.PRIMARY.DARK && RADIO_STATES.MARKED}
                onClick={() => setPrimaryTheme(THEMES.PRIMARY.DARK)}
              />
              <Radio
                label={"Light"}
                value={primary === THEMES.PRIMARY.LIGHT && RADIO_STATES.MARKED}
                onClick={() => setPrimaryTheme(THEMES.PRIMARY.LIGHT)}
              />
            </div>
          </div>
          <div className={styles["entry"]}>
            <u>Secondary Theme:</u>
            <Dropdown
              tailClassName={styles["dropdown"]}
              items={secondaryThemesList}
              selected={theme.secondary}
              setSelected={setSecondaryTheme}
              itemMap={secondaryThemesMap}
            />
          </div>
        </div>
      </div>
      <Button
        onClick={() => {
          updateUser(() => ({
            photoURL: photoURL || DEFAULT_PORTRAIT,
            displayName: displayName || "New User",
          }));
          setShouldSetupUser(false);
        }}
      >
        Submit
      </Button>
    </EmptyPageSection>
  );
};
