export const TUTORIALS = {
  INTRO: "intro",
};

export const DEFAULT_TUTORIAL_STATUSES = {
  [TUTORIALS.INTRO]: 0,
};

export const TUTORIAL_STEP_TYPES = {
  MODAL: "MODAL",
  TOOLTIP: "TOOLTIP",
};
