import { Book } from "components/Common/Icons/Book";
import { compendiumButtonId } from "constants/portalIds";
import { COMPENDIUM_PATHS } from "./routeConsts";

export const compendiumAppHeaderConfig = {
  path: COMPENDIUM_PATHS.BASE,
  staticData: {
    label: "Compendium",
    icon: ({ hovered }) => <Book hovered={hovered} />,
    buttonId: compendiumButtonId,
  },
};
