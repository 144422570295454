import { useEffect, useRef } from "react";
import { getDataFromDocument } from "../contexts/database/useMainAccessors";
import { noop } from "lodash";
import { useDocumentManager } from "contexts/database/DocumentManagerContext";

export const useDocumentSync = ({
  subscribe,
  id,
  queryKey,
  disabled,
  // this means refresh the document group, not the Group
  refetchGroup = noop,
  onUpdateSuccess = noop,
}) => {
  const prevDoc = useRef();
  const { set: setInCache } = useDocumentManager();

  useEffect(() => {
    if (disabled || !id) {
      return;
    }
    prevDoc.current = null;

    try {
      return subscribe(id, (doc) => {
        const newDocData = getDataFromDocument(doc);

        setInCache([queryKey, id], (old) => {
          prevDoc.current = old;

          if (old?.name !== newDocData?.name) {
            refetchGroup();
          }

          onUpdateSuccess(newDocData);

          return newDocData;
        });

        refetchGroup();
      });
    } catch (err) {
      console.error(err);
    }
  }, [
    disabled,
    id,
    queryKey,
    subscribe,
    refetchGroup,
    onUpdateSuccess,
    setInCache,
  ]);
};
