import { createRoute } from "@tanstack/react-router";
import { rootRoute } from "components/Router/routes/rootRoute";
import { compendiumAppHeaderConfig } from "./compendiumAppHeaderConfig";
import { COMPENDIUM_PATHS } from "./routeConsts";

export const compendiumRoute = createRoute({
  getParentRoute: () => rootRoute,
  pendingMinMs: 1000,
  ...compendiumAppHeaderConfig,
}).lazy(() => import("./lazyCompendiumRoutes").then((d) => d.compendiumRoute));

const compendiumSearchRoute = createRoute({
  getParentRoute: () => compendiumRoute,
  path: `${COMPENDIUM_PATHS.SEARCH}/$`,
}).lazy(() =>
  import("./lazyCompendiumRoutes").then((d) => d.compendiumSearchRoute)
);

const compendiumEntryRoute = createRoute({
  getParentRoute: () => compendiumRoute,
  path: `${COMPENDIUM_PATHS.ENTRY}/$`,
}).lazy(() =>
  import("./lazyCompendiumRoutes").then((d) => d.compendiumEntryRoute)
);

const compendiumIndex = createRoute({
  getParentRoute: () => compendiumRoute,
  path: "/",
}).lazy(() => import("./lazyCompendiumRoutes").then((d) => d.compendiumIndex));

compendiumRoute.addChildren([
  compendiumIndex,
  compendiumEntryRoute,
  compendiumSearchRoute,
]);
