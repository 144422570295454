import {
  collection,
  addDoc,
  getDoc,
  getDocs,
  doc,
  runTransaction,
  onSnapshot,
  setDoc,
  where,
  query,
  updateDoc,
  deleteDoc,
} from "firebase/firestore";
import { merge } from "lodash";
import { useMemo } from "react";

export const WHERE_OPERATORS = {
  EQUAL: "==",
  NOT_EQUAL: "!=",
  LESS_THAN: "<",
  LESS_THAN_OR_EQUAL: "<=",
  GREATER_THAN: ">",
  GREATER_THAN_OR_EQUAL: ">=",
  ARRAY_CONTAINS: "array-contains",
  ARRAY_CONTAINS_ANY: "array-contains-any",
  IN: "in",
  NOT_IN: "not-in",
};

export const getDataFromDocument = (doc) => ({ id: doc.id, ...doc.data() });

export const checkDocument = (db, path) => async (id) => {
  const docSnap = await getDoc(doc(db, ...path, id));

  return docSnap.exists();
};

export const addDocument = (db, path) => async (data) => {
  let docRef;

  try {
    docRef = await addDoc(collection(db, ...path), data);
    console.log("Document written with ID: ", docRef.id);
  } catch (e) {
    console.error("Error adding document: ", e);
  }

  const docSnap = await getDoc(docRef);

  return getDataFromDocument(docSnap);
};

export const addDocumentWithId = (db, path) => async (id, data) => {
  try {
    await setDoc(doc(db, "users", id), data);
    console.log("Document written with ID: ", id);
  } catch (e) {
    console.error("Error adding document: ", e);
  }

  const docSnap = await getDoc(doc(db, "users", id));

  return getDataFromDocument(docSnap);
};

export const addIgnoreDocumentWithId = (db, path) => async (id, data) => {
  const docSnap = await getDoc(doc(db, ...path, id));

  if (!docSnap.exists()) {
    return await addDocumentWithId(db, path)(id, data);
  }

  return await readDocument(db, path)(id);
};

export const getAllDocuments = (db, path) => async () => {
  try {
    const querySnapshot = await getDocs(collection(db, ...path));

    return querySnapshot.docs.map((doc) => getDataFromDocument(doc));
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const getDocumentsById = (db, path) => async (ids) => {
  const promises = ids.map((id) => {
    return readDocument(db, path)(id);
  });

  try {
    return await Promise.all(promises);
  } catch (err) {
    console.error(err);
    return [];
  }
};

export const getDocumentsWhere = (db, path) => async (a, operator, b) => {
  try {
    const querySnapshot = await getDocs(
      query(collection(db, ...path), where(a, operator, b))
    );

    if (querySnapshot.empty) {
      return [];
    }

    return querySnapshot.docs.map((doc) => getDataFromDocument(doc));
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const readDocument = (db, path) => async (id) => {
  if (!id) {
    return null;
  }

  const docSnap = await getDoc(doc(db, ...path, id));

  if (!docSnap.exists()) {
    // docSnap.data() will be undefined in this case
    console.log("No such document!", id);
    return null;
  }

  return getDataFromDocument(docSnap);
};

export const updateDocument = (db, path) => async (id, updater) => {
  await runTransaction(db, async (transaction) => {
    const docRef = doc(db, ...path, id);

    const targetDoc = await transaction.get(docRef);

    if (!targetDoc.exists()) {
      throw new Error("Document does not exist!");
    }

    const newData = updater(targetDoc);

    transaction.update(docRef, { ...newData });
  });
};
export const updateDocSync = (db, path) => async (id, data) => {
  await updateDoc(doc(db, ...path, id), data);
};
export const updateLatestDocSync =
  (db, path) =>
  async (id, updater, { shouldMergeUpdateAndData } = {}) => {
    const latestDoc = await getDoc(doc(db, ...path, id));

    const data = await updater(latestDoc);

    await updateDocSync(db, path)(id, data);

    return shouldMergeUpdateAndData
      ? merge({}, getDataFromDocument(latestDoc), data)
      : undefined;
  };

export const updateOrAddDocumentWithId = (db, path) => async (id, data) => {
  const docSnap = await getDoc(doc(db, ...path, id));

  if (!docSnap.exists()) {
    return addDocumentWithId(db, path)(id, data);
  }

  return updateDocument(db, path)(id, () => data);
};

export const subscribeToDocument = (db, path) => (id, callback) => {
  return onSnapshot(doc(db, ...path, id), {}, (doc) => {
    callback(doc);
  });
};

export const deleteDocument = (db, path) => async (id) => {
  return await deleteDoc(doc(db, ...path, id));
};

export const useMainAccessors = (db, path) => {
  return useMemo(
    () => ({
      check: checkDocument(db, path),
      add: addDocument(db, path),
      addWithId: addDocumentWithId(db, path),
      // add only if it doesn't exist
      addIgnoreWithId: addIgnoreDocumentWithId(db, path),
      getAll: getAllDocuments(db, path),
      getWhere: getDocumentsWhere(db, path),
      read: readDocument(db, path),
      readMultiple: getDocumentsById(db, path),
      update: updateDocument(db, path),
      updateSync: updateDocSync(db, path),
      updateLatestSync: updateLatestDocSync(db, path),
      updateOrAddWithId: updateOrAddDocumentWithId(db, path),
      subscribe: subscribeToDocument(db, path),
      delete: deleteDocument(db, path),
    }),
    [db, path]
  );
};
