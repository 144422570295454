export const getDocumentGroupHeaderPathFunc =
  ({ localStorageKey, path }) =>
  () => {
    const lastSelected = localStorage.getItem(localStorageKey);

    if (lastSelected) {
      return { to: `/${path}/$pageId`, params: { pageId: lastSelected } };
    }

    return { to: `/${path}` };
  };
