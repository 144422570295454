export const resizeImgToMaxDimension = (img, maxDimension) => {
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");
  const widthBigger = img.width > img.height;
  canvas.width = widthBigger
    ? maxDimension
    : (maxDimension * img.width) / img.height;
  canvas.height = widthBigger
    ? (maxDimension * img.height) / img.width
    : maxDimension;
  ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

  return canvas.toDataURL();
};

const FIVE_MB = 5 * 1000 * 1000;

export const getDataUrlFromFile = (
  file,
  { maxFileSize = FIVE_MB, maxFileDimension = 500 } = {}
) => {
  return new Promise((resolve) => {
    let resizeOnDataUrl = file.size > maxFileSize;

    const readFile = (e) => {
      if (resizeOnDataUrl) {
        const img = document.createElement("img");
        img.src = e.target.result;
        img.onload = () => {
          resolve(resizeImgToMaxDimension(img, maxFileDimension));
        };
        return;
      }

      resolve(e.target.result);
    };

    const reader = new FileReader();
    reader.addEventListener("load", readFile);
    reader.readAsDataURL(file);
  });
};

export const uploadDataUrlToCDN = async (dataUrl) => {
  const res = await fetch(process.env.REACT_APP_CDN_URL, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    method: "POST",
    body: JSON.stringify({
      dataUrl,
    }),
  });
  const json = await res.json();

  if (!res.ok) {
    throw new Error("failed to generate URL: " + json.output);
  }

  return json.output.url;
};

export const uploadFileToCDN = async (file, options) => {
  // let resizeOnDataUrl = file.size > maxFileSize;
  const dataUrl = await getDataUrlFromFile(file, options);

  return uploadDataUrlToCDN(dataUrl);
};

export const uploadUrlToCDN = (url) => uploadDataUrlToCDN(url);
