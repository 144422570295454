import { getRandIntExclusive } from "./numberUtils";

export const getWrapIndex = (i, increment, length) => {
  const finalIncrement = increment % length;
  let newI = i + finalIncrement;

  if (newI >= length) {
    newI = newI - length;
  } else if (newI < 0) {
    newI = length + newI;
  }

  return newI;
};

export const getWrap = (arr, i, increment = 1) => {
  return arr[getWrapIndex(i, increment, arr.length)];
};

export const getRandIndex = (arr) => getRandIntExclusive(arr.length);
export const getRandValue = (arr) => arr[getRandIndex(arr)];

export const getDupes = (arr) => {
  const counts = {};
  const results = [];

  arr.forEach((item) => {
    counts[item] = (counts[item] || 0) + 1;

    if (counts[item] === 2) {
      results.push(item);
    }
  });

  return results;
};
