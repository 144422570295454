import { Package } from "components/Common/Icons/Package";
import { groupsButtonId } from "constants/portalIds";
import { getDocumentGroupHeaderPathFunc } from "lib/routeUtils";
import { GROUPS_PATH, LAST_GROUPS_KEY } from "./groupsConsts";

export const groupRouteAppHeaderConfig = {
  path: GROUPS_PATH,
  staticData: {
    label: "Groups",
    icon: ({ hovered }) => <Package hovered={hovered} />,
    buttonId: groupsButtonId,
    getHeaderPath: getDocumentGroupHeaderPathFunc({
      localStorageKey: LAST_GROUPS_KEY,
      path: GROUPS_PATH,
    }),
  },
};
