import { Package as PackageIcon } from "lucide-react";
import classNames from "classnames/bind";
import styles from "./package.module.scss";

const classNameBuilder = classNames.bind(styles);

export const Package = ({ className, hovered, ...rest }) => {
  return (
    <PackageIcon
      className={classNameBuilder("package", { hovered }, className)}
      {...rest}
    />
  );
};
