import React from "react";
import styles from "./map.module.scss";
import classnames from "classnames/bind";
import { Map as MapIcon } from "lucide-react";

const classNameBuilder = classnames.bind(styles);

export const Map = ({ className, hovered, ...rest }) => {
  return (
    <MapIcon
      className={classNameBuilder("container", { hovered }, className)}
      {...rest}
    />
  );
};
