import { createRoute } from "@tanstack/react-router";
import { rootRoute } from "components/Router/routes/rootRoute";
import { groupRouteAppHeaderConfig } from "./groupRouteAppHeaderConfig";

export const groupRoute = createRoute({
  getParentRoute: () => rootRoute,
  ...groupRouteAppHeaderConfig,
}).lazy(() => import("./lazyGroupsRoutes").then((d) => d.groupRoute));

const specificGroupRoute = createRoute({
  getParentRoute: () => groupRoute,
  path: "$pageId",
  staticData: {
    label: "Group",
  },
}).lazy(() => import("./lazyGroupsRoutes").then((d) => d.specificGroupRoute));

groupRoute.addChildren([specificGroupRoute]);
