import { fpReduce, fpSet, fpUpdate } from "lib/fpLodash";
import {
  convertArrToSeparatedDiv,
  convertArrToSpacedDiv,
} from "lib/renderUtils";
import { get, groupBy, isNil, mapValues } from "lodash";

export const SETTINGS_DRAWER_BOOLEAN_DEFAULT_VALUE = true;

export const SETTINGS_DRAWER_BOOLEAN_CATEGORIES = {
  DICE: "DICE",
  CUT: "CUT",
  CHAT: "CHAT",
};

export const SETTINGS_DRAWER_BOOLEAN_CATEGORIES_CONFIG = {
  [SETTINGS_DRAWER_BOOLEAN_CATEGORIES.DICE]: {
    label: "Dice",
    key: "diceSettings",
  },
  [SETTINGS_DRAWER_BOOLEAN_CATEGORIES.CUT]: {
    label: "Cut",
    key: "cutSettings",
  },
  [SETTINGS_DRAWER_BOOLEAN_CATEGORIES.CHAT]: {
    label: "Chat",
    key: "chatSettings",
  },
};

export const SETTINGS_DRAWER_BOOLEANS = {
  DICE_ANIMATION_ENABLED: "DICE_ANIMATION_ENABLED",
  CUT_ANIMATION_ENABLED: "CUT_ANIMATION_ENABLED",
  CUT_RESET_ENABLED: "CUT_RESET_ENABLED",
  CUT_UI_VISIBLE: "CUT_UI_VISIBLE",
  OUTCOME_COLOR_ENABLED: "OUTCOME_COLOR_ENABLED",
  TWIST_COLOR_ENABLED: "TWIST_COLOR_ENABLED",
  CHAT_DICE_ICONS_VISIBLE: "CHAT_DICE_ICONS_VISIBLE",
  CUT_STRIKE_THROUGH_ENABLED: "CUT_STRIKE_THROUGH_ENABLED",
  CUT_RESULTS_DIVIDER_ENABLED: "CUT_RESULTS_DIVIDER_ENABLED",

  // oddly configured booleans with different default values,
  DICE_MUTED: "DICE_MUTED",
  CHAT_HIDDEN: "CHAT_HIDDEN",
};

const normalizeKeys = (config) =>
  mapValues(config, (val) => ({
    ...val,
    key: `${!isNil(val.keyPrefixOverride) ? val.keyPrefixOverride : (SETTINGS_DRAWER_BOOLEAN_CATEGORIES_CONFIG[val.category]?.key || "settings") + "."}${val.key}`,
  }));

export const SETTINGS_DRAWER_BOOLEANS_CONFIG = normalizeKeys({
  [SETTINGS_DRAWER_BOOLEANS.DICE_MUTED]: {
    key: "diceAudioLevel",
    label: "Dice roll sound effect",
    defaultValue: 1,
    offText: "Muted",
    getToggleValue: (value) => Boolean(value),
    onUpdate: (user) =>
      fpUpdate(
        "diceAudioLevel",
        (prevVal) => ((prevVal ?? 1) === 1 ? 0 : 1),
        user
      ),
    category: SETTINGS_DRAWER_BOOLEAN_CATEGORIES.DICE,
    keyPrefixOverride: "",
  },
  [SETTINGS_DRAWER_BOOLEANS.DICE_ANIMATION_ENABLED]: {
    key: "diceAnimationEnabled",
    label: "Dice roll animation",
    helpTooltip: convertArrToSpacedDiv([
      "When disabled, dice rolls will happen instantly, skipping the animation.",
      "If the Dice Animations and Chat UI are disabled, you will not be able to see your dice rolls.",
    ]),
    category: SETTINGS_DRAWER_BOOLEAN_CATEGORIES.DICE,
  },
  [SETTINGS_DRAWER_BOOLEANS.CUT_UI_VISIBLE]: {
    key: "cutUiVisible",
    label: "Cut UI",
    onText: "Visible",
    offText: "Hidden",
    helpTooltip: "When disabled, hides the cut UI in the dice roller.",
    category: SETTINGS_DRAWER_BOOLEAN_CATEGORIES.CUT,
  },
  [SETTINGS_DRAWER_BOOLEANS.CUT_ANIMATION_ENABLED]: {
    key: "cutAnimationEnabled",
    label: "Cut animation",
    helpTooltip: convertArrToSpacedDiv([
      "When disabled, the knife will not move when you change the cut value.",
    ]),
    category: SETTINGS_DRAWER_BOOLEAN_CATEGORIES.CUT,
  },
  [SETTINGS_DRAWER_BOOLEANS.CUT_RESET_ENABLED]: {
    key: "cutResetEnabled",
    label: "Cut reset after roll",
    helpTooltip: convertArrToSpacedDiv([
      "When enabled, the cut value is set to 0 after a roll is made.",
    ]),
    category: SETTINGS_DRAWER_BOOLEAN_CATEGORIES.CUT,
  },
  [SETTINGS_DRAWER_BOOLEANS.CHAT_HIDDEN]: {
    key: "chatHidden",
    label: "Chat UI",
    defaultValue: false,
    onText: "Visible",
    offText: "Hidden",
    getToggleValue: (value) => !value,
    helpTooltip: convertArrToSpacedDiv([
      "When disabled, hides the chat UI and all chat messages.",
      "If the Chat UI and Dice Animations are disabled, you will not be able to see your dice rolls.",
    ]),
    category: SETTINGS_DRAWER_BOOLEAN_CATEGORIES.CHAT,
    keyPrefixOverride: "",
  },
  [SETTINGS_DRAWER_BOOLEANS.OUTCOME_COLOR_ENABLED]: {
    key: "chatOutcomeColorEnabled",
    label: "Outcome color",
    helpTooltip: convertArrToSpacedDiv([
      "When enabled, the dice icon in the chat will be color-coded based on the outcome of the roll.",
      convertArrToSeparatedDiv(
        ["6: Triumph (Green)", "4-5: Conflict (Yellow)", "1-3: Disaster (Red)"],
        null
      ),
    ]),
    onText: "Colorful",
    offText: "Plain",
    category: SETTINGS_DRAWER_BOOLEAN_CATEGORIES.CHAT,
  },
  [SETTINGS_DRAWER_BOOLEANS.TWIST_COLOR_ENABLED]: {
    key: "chatTwistColorEnabled",
    label: "Twist color",
    helpTooltip:
      "When disabled, the twist icon and numbers will not be highlighted with your secondary color.",
    onText: "Colorful",
    offText: "Plain",
    category: SETTINGS_DRAWER_BOOLEAN_CATEGORIES.CHAT,
  },
  [SETTINGS_DRAWER_BOOLEANS.CHAT_DICE_ICONS_VISIBLE]: {
    key: "chatDiceIconsVisible",
    label: "Outcome icons",
    helpTooltip:
      "When disabled, hides the dice and twist icons in chat dice rolls.",
    onText: "Visible",
    offText: "Hidden",
    category: SETTINGS_DRAWER_BOOLEAN_CATEGORIES.CHAT,
  },
  [SETTINGS_DRAWER_BOOLEANS.CUT_STRIKE_THROUGH_ENABLED]: {
    key: "chatCutStrikethroughEnabled",
    label: "Cut results strikethrough",
    helpTooltip:
      "When disabled, cut results in the chat will not have a line drawn through them.",
    category: SETTINGS_DRAWER_BOOLEAN_CATEGORIES.CHAT,
  },
  // [SETTINGS_DRAWER_BOOLEANS.CUT_RESULTS_DIVIDER_ENABLED]: {
  //   key: "chatCutDividerEnabled",
  //   label: "Cut results divider",
  //   defaultValue: false,
  //   helpTooltip:
  //     "When enabled, a divider is placed between the cut and uncut results.",
  //   category: SETTINGS_DRAWER_BOOLEAN_CATEGORIES.CHAT,
  // },
  // [SETTINGS_DRAWER_BOOLEANS.CUT_STRIKE_THROUGH_ENABLED]: {
  //   key: "chatCutResultsStyle",
  //   label: "Cut Results Style",
  //   defaultValue: "STRIKE",
  //   onText: "Strikethrough",
  //   offText: "Divider",
  //   getToggleValue: (value) => value === "STRIKE",
  //   onUpdate: (prevUser) =>
  //     fpUpdate(
  //       "chatCutResultsStyle",
  //       (prev) => ((prev ?? "STRIKE") === "STRIKE" ? "DIVIDER" : "STRIKE"),
  //       prevUser
  //     ),
  //   helpTooltip: "",
  //   category: SETTINGS_DRAWER_BOOLEAN_CATEGORIES.CHAT,
  // },
});

const BOOLEAN_CONFIG_VALUES = Object.values(SETTINGS_DRAWER_BOOLEANS_CONFIG);

export const BOOLEAN_CONFIGS_BY_CATEGORY = groupBy(
  BOOLEAN_CONFIG_VALUES,
  "category"
);

export const getSettingsDrawerBooleanValue = (settingsDrawerBoolean, user) => {
  const config = SETTINGS_DRAWER_BOOLEANS_CONFIG[settingsDrawerBoolean];

  if (!config) {
    console.warn("Incorrect boolean config attempted!", settingsDrawerBoolean);
    return SETTINGS_DRAWER_BOOLEAN_DEFAULT_VALUE;
  }
  const { key, defaultValue = SETTINGS_DRAWER_BOOLEAN_DEFAULT_VALUE } = config;

  return get(user, key) ?? defaultValue;
};

export const useSettingsDrawerBooleans = ({ tempUser }) => {
  const booleanSubmitData = fpReduce(
    (acc, config) => {
      const { key, defaultValue = SETTINGS_DRAWER_BOOLEAN_DEFAULT_VALUE } =
        config;
      acc = fpSet(key, get(tempUser, key) ?? defaultValue, acc);

      return acc;
    },
    {},
    BOOLEAN_CONFIG_VALUES
  );

  return {
    booleanSubmitData,
    booleanConfigs: BOOLEAN_CONFIG_VALUES,
  };
};
