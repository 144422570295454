import { useInitializeAndCleanup } from "components/Common/hooks/useInitializeAndCleanup";
import { createContext, useContext, useMemo, useState } from "react";

export const DiceRollerContext = createContext();

export const DiceRollerProvider = ({ children }) => {
  const [name, setName] = useState("");
  const [portrait, setPortrait] = useState("");
  const [user, setUser] = useState("");

  const value = useMemo(
    () => ({ name, setName, portrait, setPortrait, user, setUser }),
    [name, portrait, user]
  );

  return (
    <DiceRollerContext.Provider value={value}>
      {children}
    </DiceRollerContext.Provider>
  );
};

export const useDiceRoller = ({
  name: propName,
  portrait: propPortrait,
  user: propUser,
} = {}) => {
  const { setName, setPortrait, name, portrait, user, setUser, ...rest } =
    useContext(DiceRollerContext);

  useInitializeAndCleanup(propName, setName);
  useInitializeAndCleanup(propPortrait, setPortrait);
  useInitializeAndCleanup(propUser, setUser);

  return {
    ...rest,
    chatContent: { name, portrait, user },
    setName,
    setPortrait,
  };
};
