import { Button } from "components/Common/Button";
import styles from "./iFrameAppHeader.module.scss";
import { Header } from "components/Common/Header";
import { ArrowLeft, ArrowRight } from "lucide-react";
import { isNil } from "lodash";

export const IFrameAppHeader = ({ headerPortal }) => {
  const nav = window?.navigation || {};
  const isAtStateZero = window.history.state?.idx === 0;

  return (
    <Header
      title={
        <div className={styles["nav-buttons"]}>
          <Button
            onClick={() => window?.history.back()}
            disabled={
              (!isNil(nav.canGoBack) && !nav.canGoBack) || isAtStateZero
            }
          >
            <ArrowLeft />
          </Button>
          <Button
            disabled={!isNil(nav.canGoForward) && !nav.canGoForward}
            onClick={() => window?.history.forward()}
          >
            <ArrowRight />
          </Button>
        </div>
      }
      className={styles["iframe-header"]}
    >
      {headerPortal}
    </Header>
  );
};
