import { BulletList } from "components/Common/List/BulletList";
import { TUTORIALS } from "components/Tutorial/consts/tutorialConsts";
import { useTutorial } from "contexts/database/TutorialContext";
import { Modal } from "components/Common/Modal";
import { usePortal } from "contexts/PortalContext";
import { memo, useEffect, useMemo } from "react";
import { versions } from "./versions";
import styles from "./changeLog.module.scss";
import { useAuth } from "contexts/AuthContext";
import { DIRECTION, Divider } from "components/Common/Divider";
import * as semver from "semver";

export const ChangeLog = ({ portalProps, subtitle, versionDivider }) => {
  return (
    <Modal
      portalProps={portalProps}
      title="Changelog"
      subtitle={subtitle}
      contentClassName={styles["modal-content"]}
    >
      {versions.map(({ version, date, changes }, index) => (
        <div key={version}>
          {index !== 0 && version === versionDivider && (
            <Divider direction={DIRECTION.HORIZONTAL} />
          )}
          <div className={styles["version-container"]}>
            <div className={styles["version"]}>{`v${version}`}</div>
            <div>{`(${date})`}</div>
          </div>
          <BulletList items={changes} />
        </div>
      ))}
    </Modal>
  );
};

export const InitialLoadChangeLog = memo(() => {
  const { user, updateUser } = useAuth();
  const lastLoadedVersion = user?.lastLoadedVersion;
  // cache the first truthy version of lastLoadedVersion because updateUser will mutate it
  const versionDivider = useMemo(
    () => lastLoadedVersion,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [Boolean(lastLoadedVersion)]
  );
  const portalProps = usePortal();
  const { getTutorialStatus } = useTutorial();
  const introTutorialStatus = getTutorialStatus(TUTORIALS.INTRO);
  const newestVersion = versions[0].version;
  const hasNewerVersion =
    introTutorialStatus !== 0 &&
    user &&
    (!lastLoadedVersion || semver.lt(lastLoadedVersion, newestVersion));
  useEffect(() => {
    if (hasNewerVersion) {
      portalProps.setIsPortalComponentRendered(true);
      updateUser(() => ({
        lastLoadedVersion: newestVersion,
      }));
    }
  }, [
    hasNewerVersion,
    portalProps,
    updateUser,
    newestVersion,
    lastLoadedVersion,
  ]);
  return (
    <ChangeLog
      portalProps={portalProps}
      subtitle={"We've released an update!"}
      versionDivider={versionDivider}
    />
  );
});
