import { useMatches } from "@tanstack/react-router";
import { compact } from "lodash";

export const usePageConfig = (configsToFind) => {
  let configs = [];
  const matches = useMatches();
  configs = compact(matches.map(match => match?.staticData))

  if (!configsToFind) {
    return configs;
  }

  return configs.reduce(
    (acc, curr) => {
      for (let i = 0; i < configsToFind.length; i++) {
        const key = configsToFind[i];
        if (curr[key] !== undefined) {
          acc[i] = curr[key];
        }
      }
      return acc;
    },
    configsToFind.map(() => undefined)
  );
};
