import { usePortal } from "contexts/PortalContext";
import { stubTrue } from "lodash";
import { useCallback } from "react";

/**
 * useManagedPortal
 *
 * use this hook if you want your portal to await a function before opening/closing
 *
 * onInterceptPortalOpen is called whenever the user attempted to open the modal
 *
 * onInterceptPortalClose is called whenever the user attempted to close the modal,
 *    e.g. by using the ESC button or clicking outside the modal or calling setIsPortalComponentRendered(false)
 */
export const useManagedPortal = ({
  onInterceptPortalOpen = stubTrue,
  onInterceptPortalClose,
} = {}) => {
  const portalProps = usePortal({ onInterceptPortalClose });

  const { setIsPortalComponentRendered, ...rest } = portalProps;

  const finalSetIsPortalComponentRenderer = useCallback(
    async (value, override) => {
      let handler = stubTrue;

      if (value) {
        handler = onInterceptPortalOpen;
      } else if (onInterceptPortalClose) {
        handler = onInterceptPortalClose;
      }

      const res = await handler(value);

      if (res) {
        setIsPortalComponentRendered(value, override);
      }
    },
    [
      onInterceptPortalClose,
      onInterceptPortalOpen,
      setIsPortalComponentRendered,
    ]
  );

  return {
    ...rest,
    setIsPortalComponentRendered: finalSetIsPortalComponentRenderer,
  };
};
