export const Slash = ({ slashClassName, ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      {...rest}
    >
      {/* path is hardcoded (normally should be 22 2 2 22) however we need it a bit
    smaller to accommodate fitting in the radio. if we need this for anything else
    we should probably compute the path based on the width/height */}
      <path className={slashClassName} d="M15 9 9 15"></path>
    </svg>
  );
};
