import React, { useCallback, useEffect, useState } from "react";
import { useAuth } from "../../contexts/AuthContext";
import styles from "./home.module.scss";
import { Button } from "../Common/Button";
import { GoogleSignIn } from "./GoogleSignIn";
import { EmptyPageSection } from "components/Common/Section/EmptyPageSection";
import { useIFrame } from "contexts/IframeContext";
import { COMPENDIUM_PATHS } from "components/Compendium/consts/routeConsts";
import { Navigate } from "@tanstack/react-router";
import { usePage } from "hooks/usePage";
import { SetupUser } from "./SetupUser";
import { ResetPassword } from "./ResetPassword";
import { usePrevious } from "react-use";
import { BaseAccountForm } from "./BaseAccountForm";
import { GoogleSignUp } from "./GoogleSignUp";
import { TriangleAlert } from "lucide-react";

const HOME_MODES = {
  CREATE: "CREATE",
  RESET: "RESET",
};

export const Home = ({ children }) => {
  const {
    readUser,
    authLoaded,
    userId,
    loading,
    user,
    signIn,
    createUser,
    error,
    setError,
    authUser,
    shouldSetupUser,
    setShouldSetupUser,
  } = useAuth();
  const { isIFrame } = useIFrame();
  const [base] = usePage();
  const [mode, setMode] = useState(null);
  const prevMode = usePrevious(mode);

  useEffect(() => {
    if (prevMode !== mode) {
      setError(null);
    }
  }, [mode, prevMode, setError]);

  useEffect(() => {
    if (!isIFrame) {
      readUser();
    }
  }, [userId, readUser, isIFrame]);

  const onSignInClick = useCallback(
    (email, password) =>
      signIn({
        email,
        password,
      }),
    [signIn]
  );

  const onSignUpClick = useCallback(
    async (email, password) => {
      try {
        await createUser({ email, password });
        setMode(null);
      } catch (err) {
        console.error(err);
      }
    },
    [createUser]
  );

  const goBack = () => setMode(null);
  const BackButton = (
    <Button onClick={goBack} className={styles["back"]}>
      Back
    </Button>
  );

  if (isIFrame && base !== COMPENDIUM_PATHS.BASE) {
    return <Navigate to={`/${COMPENDIUM_PATHS.BASE}`} />;
  }

  // if you have a user but auth isn't loaded yet
  if (!isIFrame && !authLoaded && userId) {
    return (
      <EmptyPageSection
        label={"Please wait a moment..."}
        delayedContentClassName={styles["delayed-content"]}
        delayedContent={
          <div className={styles["delayed"]}>
            <i>There seems to be an issue with authentication!</i>
            <div className={styles["red-error"]}>
              {error?.code || "Unknown Error"}
            </div>
            <div>
              If refreshing the page does not resolve this issue, please try
              clicking the button below
            </div>
            <Button
              onClick={() => {
                localStorage.clear();
                window.location.reload();
              }}
            >
              Clear local storage and refresh
            </Button>
            <div>
              If the button does not fix the issue, please send an email to{" "}
              <u>charsmithapp@gmail.com</u> and we'll try to get it resolved as
              soon as possible
            </div>
          </div>
        }
        delayedContentTimeout={8000}
      >
        Checking Authentication
      </EmptyPageSection>
    );
  }

  if (shouldSetupUser) {
    return <SetupUser user={user} setShouldSetupUser={setShouldSetupUser} />;
  }

  // if we have a user in state & there's a user in auth
  if (isIFrame || (authUser && user)) {
    return children;
  }

  let props = {};

  if (loading) {
    props = {
      children: "Signing in...",
    };
  } else if (mode === HOME_MODES.CREATE) {
    props = {
      children: (
        <BaseAccountForm
          error={error}
          submitLabel={"Create account"}
          onSubmit={onSignUpClick}
          googleButton={<GoogleSignUp />}
          onGoogleClick={signIn}
        >
          <div className={styles["warning"]}>
            <TriangleAlert />
            If you use a fake email, we will not be able to reset your password!
          </div>
        </BaseAccountForm>
      ),
      icon: null,
      headerSuffix: BackButton,
      label: "Create a new account",
    };
  } else if (mode === HOME_MODES.RESET) {
    props = {
      children: <ResetPassword goBack={goBack} />,
      icon: null,
      headerSuffix: BackButton,
      label: "Reset your Password",
      textContainerClassName: styles["reset"],
    };
  } else {
    props = {
      icon: null,
      children: (
        <BaseAccountForm
          error={error}
          loading={loading}
          submitLabel={"Sign In"}
          onSubmit={onSignInClick}
          googleButton={<GoogleSignIn />}
          onGoogleClick={signIn}
        >
          <u
            onClick={() => setMode(HOME_MODES.RESET)}
            className={styles["reset-link"]}
          >
            Forgot password?
          </u>
        </BaseAccountForm>
      ),
      underSection: (
        <div className={styles["under-section"]}>
          <div className={styles["prompt"]}>New to Charsmith?</div>
          <Button
            onClick={() => setMode(HOME_MODES.CREATE)}
            colorful
            className={styles["button"]}
          >
            <div>Create your account here!</div>
          </Button>
        </div>
      ),
    };
  }

  return <EmptyPageSection label={"Sign in to get started!"} {...props} />;
};
