import { User } from "components/Common/Icons/User";
import { charactersButtonId } from "constants/portalIds";
import { getDocumentGroupHeaderPathFunc } from "lib/routeUtils";
import { CHARACTERS_PATH, LAST_CHARACTER_KEY } from "./charactersConsts";

export const characterRouteAppHeaderConfig = {
  path: CHARACTERS_PATH,
  staticData: {
    label: "Characters",
    icon: ({ hovered }) => <User hovered={hovered} />,
    buttonId: charactersButtonId,
    requiresGroup: true,
    getHeaderPath: getDocumentGroupHeaderPathFunc({
      localStorageKey: LAST_CHARACTER_KEY,
      path: CHARACTERS_PATH,
    }),
  },
};
