import React from "react";
import styles from "./drawer.module.scss";
import classnames from "classnames/bind";
import { useTheme } from "../../contexts/ThemeContext";
import { useTransition } from "../../hooks/transition/useTransition";
import { Header } from "./Header";
import { Button } from "./Button";
import { X } from "lucide-react";
import { Portal } from "../../contexts/PortalContext";
const classNameBuilder = classnames.bind(styles);

export const DRAWER_POSITION = {
  LEFT: "left",
  RIGHT: "right",
  TOP: "top",
  BOTTOM: "bottom",
};

export const Drawer = ({
  children,
  position = DRAWER_POSITION.RIGHT,
  portalProps,
  contentClassName,
}) => {
  const { isPortalComponentRendered, setIsPortalComponentRendered } =
    portalProps;
  const { themeClassNames } = useTheme();
  const [drawerRef, isTransitionRendered, isTransitionEntering] = useTransition(
    isPortalComponentRendered
  );

  return (
    <Portal portalProps={portalProps}>
      {isTransitionRendered ? (
        <div
          ref={drawerRef}
          className={classNameBuilder("drawer", ...themeClassNames, {
            [position]: true,
            opened: isTransitionEntering,
          })}
        >
          <Header title={"Settings"}>
            <Button
              dark
              icon
              large
              onClick={() => setIsPortalComponentRendered(false)}
            >
              <X />
            </Button>
          </Header>
          <div className={classNameBuilder("content", contentClassName)}>
            {children}
          </div>
        </div>
      ) : null}
    </Portal>
  );
};
