import styles from "./footer.module.scss";
import { Modal } from "components/Common/Modal";
import { patreonLink, purchaseLink, supportEmail } from "constants/contactInfo";
import { usePortal } from "contexts/PortalContext";
import { Patreon } from "components/Common/Icons/Patreon";
import { Mail, ExternalLink } from "lucide-react";
import classNames from "classnames/bind";
import { Image } from "components/Common/Image/Image";
import mascot from "./mascot.png";
import FireflyLogo from "./firefly-logo.png";
import { usePageConfig } from "hooks/usePageConfig";
import { useIFrame } from "contexts/IframeContext";

const classNameBuilder = classNames.bind(styles);

export const Footer = () => {
  const aboutUsPortal = usePortal();
  const contactPortal = usePortal();
  const [shouldHideFooter] = usePageConfig(["shouldHideFooter"]);
  const { isIFrame } = useIFrame();

  if (shouldHideFooter || isIFrame) return null;

  return (
    <div className={styles["container"]}>
      <div className={styles["inner-container"]}>
        <Image src={mascot} className={styles["mascot"]} position={"top"} />
        <Modal
          title={"About Us"}
          contentClassName={styles["modal"]}
          portalProps={aboutUsPortal}
        >
          <div className={styles["links"]}>
            <div>
              Hello! We're two web developers who like playing TTRPGs and
              building useful apps. We stumbled on the Wildsea system and fell
              in love with it, so we decided to make a character sheet to help
              us keep track of our games. But things quickly got out of hand as
              we kept coming up with more and more cool features and ideas...
            </div>
            <div>
              We hope this character sheet can make your sessions of the Wildsea
              more fun and fluid. If you've enjoyed using it, you can help keep
              it running through our Patreon. 100% of the funds will be used to
              fund the servers; we intend to keep the app entirely free and
              nonprofit.
            </div>
            <PatreonLink />
            <div>
              We would greatly appreciate if you send any issues, bugs,
              feedback, or even anything you love about the app to our support
              email and we'll try our best to get back to you ASAP!
            </div>
            <EmailLink />
          </div>
        </Modal>
        <Modal
          title={"Contact"}
          contentClassName={styles["modal"]}
          portalProps={contactPortal}
        >
          <div className={styles["links"]}>
            <EmailLink />
            <PatreonLink />
          </div>
        </Modal>
        <div className={styles["legal"]}>
          <div className={styles["main"]}>
            The Wildsea is the copyright of Quillhound Studios and Felix Isaacs
          </div>
          <i className={styles["sub"]}>
            This <u>Wildsea Digital Character Sheet</u> is an independent
            production by the members of charsmith and is not affiliated with
            Mythworks or Felix Isaacs. It is published under the By Firefly’s
            License.
          </i>
        </div>
        <div className={styles["links"]}>
          <div className={styles["link-group"]}>
            <div className={styles["main"]}>
              If you enjoy this app, please help keep our servers running:
            </div>
            <PatreonLink />
          </div>
          <div className={styles["link-group"]}>
            <div className={styles["main"]}>
              Support Mythworks by purchasing the Wildsea:
            </div>
            <GeneralLink href={purchaseLink}>
              Browse the Wildsea collection
            </GeneralLink>
          </div>
          <div className={styles["link-group"]}>
            <div
              className={styles["link"]}
              onClick={() => aboutUsPortal.setIsPortalComponentRendered(true)}
            >
              About Us
            </div>
            <div
              className={styles["link"]}
              onClick={() => contactPortal.setIsPortalComponentRendered(true)}
            >
              Contact
            </div>
          </div>
        </div>
        <div className={styles["small-logos"]}>
          <Image src={mascot} width={100} position={"top"} />
          <Image src={FireflyLogo} width={100} contain />
        </div>
        <Image
          src={FireflyLogo}
          className={styles["firefly-logo-big"]}
          width={150}
          height={150}
          position={"center"}
          contain
        />
      </div>
    </div>
  );
};

export const EmailLink = () => (
  <Link href={`mailto: ${supportEmail}`}>
    <Mail /> {supportEmail}
  </Link>
);

const PatreonLink = () => (
  <Link href={patreonLink}>
    <Patreon />
    Support this app on Patreon!
  </Link>
);

const GeneralLink = ({ href, children }) => (
  <Link href={href}>
    <ExternalLink />
    {children}
  </Link>
);

const Link = ({ children, href }) => (
  <a
    className={classNameBuilder("link", "fit-width")}
    target="_blank"
    href={href}
    rel="noreferrer"
  >
    {children}
  </a>
);
