import { useEffect } from "react";

export const useInitializeAndCleanup = (
  value,
  updater,
  { cleanupValue = "" } = {}
) => {
  useEffect(() => {
    if (!value) {
      return;
    }

    updater(value);

    return () => updater(cleanupValue);
  }, [value, updater, cleanupValue]);
};
