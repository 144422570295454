import { createContext, useContext } from "react";

export const PathContext = createContext("");

export const PathContextProvider = ({ children, path }) => {
  const prevPath = usePath();

  return (
    <PathContext.Provider value={prevPath + "/" + path}>
      {children}
    </PathContext.Provider>
  );
};

export const usePath = () => {
  return useContext(PathContext);
};
