import { BellRing, TriangleAlert } from "lucide-react";
import { Patreon } from "../Icons/Patreon";
import { Tooltip } from "../Tooltip/Tooltip";

export const BULLET_LIST_ITEM_TYPE = {
  WARNING: "WARNING",
  IMPORTANT: "IMPORTANT",
  NORMAL: "NORMAL",
  PATREON: "PATREON",
};

export const BULLET_LIST_ITEM_TYPE_CONFIG = {
  [BULLET_LIST_ITEM_TYPE.NORMAL]: {
    style: "normal",
  },
  [BULLET_LIST_ITEM_TYPE.PATREON]: {
    style: "patreon",
    icon: (props) => (
      <div>
        <Patreon {...props} />
        <Tooltip position={"top-start"}>Requested by a Patreon user</Tooltip>
      </div>
    ),
  },
  [BULLET_LIST_ITEM_TYPE.WARNING]: {
    style: "warning",
    icon: TriangleAlert,
  },
  [BULLET_LIST_ITEM_TYPE.IMPORTANT]: {
    style: "important",
    icon: BellRing,
  },
};
