export const KEYS = {
  ALL: "All",
  ESCAPE: "Escape",
  ENTER: "Enter",
  TAB: "Tab",
  BACKSPACE: "Backspace",
  SPACE: "Space",
  ARROW_UP: "ArrowUp",
  ARROW_DOWN: "ArrowDown",
  ARROW_LEFT: "ArrowLeft",
  ARROW_RIGHT: "ArrowRight",
};

export const handleEnterKey = (callback) => (e) => {
  if (e.key === KEYS.ENTER) {
    callback(e);
  }
};
