export const groupsButtonId = "groups-button";
export const generateInviteButtonId = "generate-invite-button";

export const charactersButtonId = "characters-button";

export const shipsButtonId = "ships-button";

export const gameMasterButtonId = "gm-button";

export const compendiumButtonId = "compendium-button";
export const compendiumSearchId = "compendium-search";

export const reloadButtonId = "reload-button";
export const settingsButtonId = "settings-button";

export const addItemButtonId = "add-item-button";
export const getPageAddItemButtonId = (page) => `${page}-add-item-button`;
export const selectItemDropdownId = "select-item-dropdown";

export const diceRollerButtonId = "dice-roller-button";
export const compendiumModalButtonId = "compendium-modal-button";
export const ChatButtonId = "chat-button";
