import styles from "./tooltipStyleContext.module.scss";
import classnames from "classnames/bind";
import { createContext, useContext, useMemo } from "react";
import { useTheme } from "./ThemeContext";

const classNameBuilder = classnames.bind(styles);

const TooltipStyleContext = createContext();

export const TOOLTIP_SIZES = {
  HUGE: "huge",
  UNRESTRICTED: "unrestricted",
};

export const TooltipStyleProvider = ({
  className,
  size,
  style,
  boxStyle,
  tailStyle,
  children,
  tailClassName,
  scrollRef: propsScrollRef,
}) => {
  const { scrollRef } = useContext(TooltipStyleContext) || {};

  const { themeClassNames } = useTheme();

  const value = useMemo(
    () => ({
      boxClassName: classNameBuilder(
        "tooltip",
        ...themeClassNames,
        className,
        size
      ),
      tailClassName: classNameBuilder("tail", tailClassName),
      boxStyle,
      tailStyle: {
        boxShadow: "3px 0px 8px 0 rgba(0,0,0,0.25)",
        ...tailStyle,
      },
      style: {
        // TODO: fix react-mint border bug
        background: "var(--background)",
        transform: "translateY(0)",
        ...style,
      },
      scrollRef: propsScrollRef || scrollRef,
    }),
    [
      boxStyle,
      className,
      propsScrollRef,
      scrollRef,
      size,
      style,
      tailClassName,
      tailStyle,
      themeClassNames,
    ]
  );

  return (
    <TooltipStyleContext.Provider value={value}>
      {children}
    </TooltipStyleContext.Provider>
  );
};

export const useScrollRef = () => useContext(TooltipStyleContext)?.scrollRef;

export const useTooltipStyle = ({
  boxClassName,
  size = TOOLTIP_SIZES.NORMAL,
  style,
  boxStyle,
  tailStyle,
}) => {
  const tooltipProps = useContext(TooltipStyleContext);

  return {
    ...tooltipProps,
    boxClassName: classNameBuilder(
      tooltipProps.boxClassName,
      boxClassName,
      size
    ),
    style: {
      ...tooltipProps.style,
      ...style,
    },
    boxStyle: {
      ...tooltipProps.boxStyle,
      ...boxStyle,
    },
    tailStyle: {
      ...tooltipProps.tailStyle,
      ...tailStyle,
    },
  };
};
