import { useState } from "react";
import { Button } from "./Button";
import { isFunction } from "lodash/fp";

export const HoverableButton = ({ children, ...props }) => {
  const [hovered, setHovered] = useState(false);
  return (
    <Button
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      children={isFunction(children) ? children({ hovered }) : children}
      {...props}
    />
  );
};
