import { useEffect, useState } from "react";
import { useGroupsAccessors } from "../../contexts/database/useGroupsAccessors";
import styles from "./joinGroup.module.scss";
import { usePath } from "../Router/PathContext";
import { useMatch, useNavigate } from "@tanstack/react-router";
import { HeartCrack } from "lucide-react";
import { useAuth } from "../../contexts/AuthContext";
import { LAST_GROUPS_KEY, MAX_RECENT_GROUPS } from "./constants/groupsConsts";
import { Button } from "components/Common/Button";
import { useQuery } from "@tanstack/react-query";
import { EmptyPageSection } from "components/Common/Section/EmptyPageSection";
import { Helmet } from "react-helmet";
import { EmailLink } from "components/Footer/Footer";
import { useLocalStorageSetter } from "hooks/useLocalStorage";

const JOIN_GROUP_QUERY_KEY = "JOIN_GROUP_GROUP";

export const JoinGroup = () => {
  const { user, updateUser } = useAuth();
  const { read, update: updateGroup } = useGroupsAccessors();
  const navigate = useNavigate();
  const path = usePath();
  const groupId = useMatch(path)?.params?.groupId;
  const [error, setError] = useState();
  const [updateInProgress, setUpdateInProgress] = useState(false);
  const [hasAcceptedGroupInv, setHasAcceptedGroupInv] = useState(false);
  const setLocalStorageGroupId = useLocalStorageSetter(LAST_GROUPS_KEY);
  const {
    data: group,
    error: queryError,
    isFetching: loading,
  } = useQuery({
    queryKey: [JOIN_GROUP_QUERY_KEY, groupId],
    queryFn: () => read(groupId),
    cacheTime: 0,
    staleTime: Infinity,
  });

  useEffect(() => {
    if (!groupId || !hasAcceptedGroupInv) {
      return;
    }

    const update = async () => {
      try {
        await updateGroup(groupId, (doc) => {
          const docUsers = doc.data().users || [];

          if (docUsers.indexOf(user.uid) !== -1) {
            return {};
          }

          return {
            users: [...(doc.data().users || []), user.uid],
          };
        });
        await updateUser((doc) => {
          const recentlyUsedGroups = doc.data().recentlyUsedGroups || [];

          return {
            recentlyUsedGroups: [
              groupId,
              ...recentlyUsedGroups.filter((i) => i !== groupId),
            ].slice(0, MAX_RECENT_GROUPS),
          };
        });
      } catch (err) {
        console.error(err);
        setError(err.message);
        setUpdateInProgress(false);
      }

      setLocalStorageGroupId(groupId);

      navigate({
        to: `/groups/$pageId`,
        params: { pageId: groupId },
      });
    };

    update();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupId, hasAcceptedGroupInv]);

  let content;

  if (loading) {
    content = (
      <EmptyPageSection label={`Please wait a moment`}>
        Fetching Group data...
      </EmptyPageSection>
    );
  } else if (error || queryError) {
    content = (
      <div className={styles["error-container"]}>
        <h1>Failed to join group!</h1>
        <HeartCrack className={styles["error"]} size={200} />
        <h3 className={styles["error"]}>
          ERROR: {error || queryError?.message || queryError}
        </h3>
        <p>
          Please ask the group owner to provide you with another invitation
          link.
        </p>
        <p>
          If you continue to have issues, please send an email to <EmailLink />
        </p>
      </div>
    );
  } else if (!hasAcceptedGroupInv) {
    content = (
      <>
        <EmptyPageSection label={`Group Name: ${group?.name}`} icon={null}>
          <div className={styles["accept-form"]}>
            <div>Are you sure you want to join this group?</div>
            <div className={styles["buttons"]}>
              <Button
                onClick={() => {
                  navigate({
                    to: `/`,
                  });
                }}
              >
                Cancel
              </Button>
              <Button
                onClick={() => {
                  setHasAcceptedGroupInv(true);
                  setUpdateInProgress(true);
                }}
                colorful
              >
                Yes
              </Button>
            </div>
          </div>
        </EmptyPageSection>
      </>
    );
  } else if (updateInProgress) {
    content = (
      <EmptyPageSection label={`Please wait a moment`}>
        Joining Group...
      </EmptyPageSection>
    );
  } else {
    content = (
      <EmptyPageSection label={`Please wait a moment`} icon={null}>
        Something went wrong...try refreshing the page
      </EmptyPageSection>
    );
  }

  return (
    <div className={styles["container"]}>
      <Helmet>
        <title>Join {group?.name || "Group"}</title>
      </Helmet>
      {content}
    </div>
  );
};
