import { Button } from "../Button";
import styles from "./footerButtons.module.scss";
import classnames from "classnames/bind";

const classNameBuilder = classnames.bind(styles);

export const FooterButtons = ({
  className,
  cancelClassName,
  submitClassName,

  onCancel,
  onSubmit,

  cancelText = "Cancel",
  submitText = "Submit",

  submitDisabled,
}) => {
  return (
    <div className={classNameBuilder("container", className)}>
      {onCancel && (
        <Button
          className={classNameBuilder("button", cancelClassName)}
          onClick={onCancel}
        >
          {cancelText}
        </Button>
      )}
      {onSubmit && (
        <Button
          className={classNameBuilder("button", submitClassName)}
          onClick={onSubmit}
          disabled={submitDisabled}
        >
          {submitText}
        </Button>
      )}
    </div>
  );
};
