import { Section } from "components/Common/Section";
import styles from "./settingsDrawerKeybinds.module.scss";

export const SettingsDrawerKeybinds = () => {
  return (
    <Section label="Keybinds" isSubSection>
      <div className={styles["container"]}>
        <div className={styles["keybinds"]}>
          <span>Toggle selected Character</span>
          <span>[ ALT + SHIFT + Up/Down Arrow ] </span>
          <span>Toggle selected Page</span>
          <span>[ ALT + SHIFT + Left/Right Arrow ] </span>
          <span>Toggle Mini Compendium</span>
          <span>[ ALT + SHIFT + C ] </span>
          <span>Roll X Dice</span>
          <span>[ ALT + SHIFT + Number ] </span>
          <span>Toggle Dice Roller</span>
          <span>[ ALT + SHIFT + D ] </span>
          <span>Enter Chat</span>
          <span>[ ENTER ] </span>
          <span>Toggle Chat bar</span>
          <span>[ ALT + SHIFT + W ] </span>
          <span>Toggle Chat history</span>
          <span>[ ALT + SHIFT + E ] </span>
          <span>Export Sheet Data</span>
          <span>[ CTRL + S ] </span>
        </div>
      </div>
    </Section>
  );
};
