import { noop } from "lodash";
import { useState, useRef, useEffect, useLayoutEffect } from "react";

export const useTransition = (inProp, ref, onTransitionEnd = noop, delay) => {
  const [isEntering, setIsEntering] = useState(null);
  const [isRendered, setIsRendered] = useState(false);
  const localRef = useRef(null);
  const newRef = ref || localRef;
  const el = newRef.current;

  useLayoutEffect(() => {
    if (inProp) {
      let animationFrame;
      let timeout;
      setIsRendered(true);
      const startAnimation = () => {
        animationFrame = window.requestAnimationFrame(() =>
          setIsEntering(true)
        );
      };
      if (delay) {
        timeout = setTimeout(() => {
          startAnimation();
        }, delay);
      } else {
        startAnimation();
      }
      return () => {
        window.cancelAnimationFrame(animationFrame);
        clearTimeout(timeout);
      };
    } else if (el) {
      setIsEntering(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inProp]);

  const leaving = el && isEntering === false && isRendered;

  useEffect(() => {
    if (leaving) {
      const onLocalTransitionEnd = () => {
        setIsRendered(false);
        setIsEntering(null);
        onTransitionEnd();
      };
      el.addEventListener("transitionend", onLocalTransitionEnd);
      return () => {
        el.removeEventListener("transitionend", onLocalTransitionEnd);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leaving]);
  return [newRef, isRendered, isEntering];
};
