export const CHARACTERS_PATH = "characters";
export const LAST_CHARACTER_KEY = "lastCharacterId";

export const CHARACTER_WIZARD_PATH = "wizard";
export const CHARACTER_WIZARD_QUICKSTART_PATH = "quickstart";

export const getFullCharacterWizardPath = (path) =>
  `/${CHARACTERS_PATH}/${CHARACTER_WIZARD_PATH}/${path}`;

export const CHARACTERS_MEMBER_KEY = "characters";
export const DEFAULT_TARGET_IDS = [];
