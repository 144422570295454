import React, { useState } from "react";
import styles from "./resetPassword.module.scss";
import { InputWithButton } from "components/Common/InputWithButton";
import { ArrowRight, Check } from "lucide-react";
import { useAuth } from "contexts/AuthContext";
import { RESET_PASSWORD_ERRORS, getErrorLabel } from "./errorConsts";
import { Loader } from "components/Common/Loader";

export const ResetPassword = ({ goBack }) => {
  const { resetPassword, error } = useAuth();
  const [hasSentReset, setHasSentReset] = useState(false);
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  if (hasSentReset) {
    return (
      <div className={styles["confirmation"]}>
        <Check className={styles["check"]} />
        <div>
          You should receive an email shortly that will let you reset your
          password.
        </div>
        <br />
        <div>
          Click{" "}
          <u onClick={goBack} className={styles["link"]}>
            here
          </u>{" "}
          to return to the sign in page.
        </div>
      </div>
    );
  }

  return (
    <>
      <InputWithButton
        reverse
        className={styles["input"]}
        placeholder="Email"
        buttonChildren={loading ? <Loader /> : <ArrowRight />}
        value={email}
        inputDisabled={loading}
        buttonDisabled={loading}
        autoFocus
        onChange={(e) => setEmail(e.target.value)}
        onSubmit={async () => {
          try {
            setLoading(true);
            await resetPassword(email);
            setHasSentReset(true);
          } catch (err) {
            console.log(err);
          } finally {
            setLoading(false);
          }
        }}
      />

      {error && (
        <div className={styles["err"]}>
          {getErrorLabel(error, RESET_PASSWORD_ERRORS)}
        </div>
      )}
    </>
  );
};
