import React from "react";
import styles from "./header.module.scss";
import { useTheme } from "../../contexts/ThemeContext";
import classnames from "classnames/bind";

const classNameBuilder = classnames.bind(styles);

export const Header = ({
  title,
  titleIcon,
  titleIconClassName,
  subtitle,
  children,
  className,
  titleContainerClassName,
  titleClassName,
  subtitleClassName,
  hideOverflow,
}) => {
  const { themeClassNames } = useTheme();
  return (
    <div className={classNameBuilder("header", ...themeClassNames, className)}>
      <div
        className={classNameBuilder(
          "title-container",
          { "hide-overflow": hideOverflow },
          titleContainerClassName
        )}
      >
        {titleIcon && (
          <div className={classNameBuilder("title-icon", titleIconClassName)}>
            {titleIcon}
          </div>
        )}
        <div
          className={classNameBuilder(
            "title",
            { "hide-overflow": hideOverflow },
            titleClassName
          )}
        >
          {title}
        </div>
        <div className={classNameBuilder("subtitle", subtitleClassName)}>
          {subtitle}
        </div>
      </div>
      {children}
    </div>
  );
};
