import styles from "./user.module.scss";
import classnames from "classnames/bind";

const classNameBuilder = classnames.bind(styles);

export const User = ({ className, hovered, animated, ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className={classNameBuilder(
        "container",
        { hovered, animated },
        className
      )}
    >
      <path d="M19 21v-2a4 4 0 0 0-4-4H9a4 4 0 0 0-4 4v2" />
      <circle cx="12" cy="7" r="4" className={styles["head"]} />
    </svg>
  );
};
