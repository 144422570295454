import React from "react";
import styles from "./divider.module.scss";
import classnames from "classnames/bind";
import { useTheme } from "../../contexts/ThemeContext";

const classNameBuilder = classnames.bind(styles);

export const DIRECTION = {
  HORIZONTAL: "horizontal",
  VERTICAL: "vertical",
};

export const Divider = ({
  direction = DIRECTION.VERTICAL,
  className,
  secondary,
}) => {
  const { themeClassNames } = useTheme();
  return (
    <div
      className={classNameBuilder(
        "divider",
        ...themeClassNames,
        {
          [direction]: true,
          secondary,
        },
        className
      )}
    />
  );
};
