import { Map } from "components/Common/Icons/Map";
import { gameMasterButtonId } from "constants/portalIds";
import { getDocumentGroupHeaderPathFunc } from "lib/routeUtils";
import { GAME_MASTER_PATH, LAST_GAME_MASTER_KEY } from "./gameMasterConsts";

export const gameMasterAppHeaderConfig = {
  path: GAME_MASTER_PATH,
  staticData: {
    label: "GMs",
    requiresGroup: true,
    icon: ({ hovered }) => <Map hovered={hovered} />,
    buttonId: gameMasterButtonId,
    getHeaderPath: getDocumentGroupHeaderPathFunc({
      localStorageKey: LAST_GAME_MASTER_KEY,
      path: GAME_MASTER_PATH,
    }),
  },
};
