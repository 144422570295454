import { useAuth } from "contexts/AuthContext";
import React, {
  createContext,
  useContext,
  useCallback,
  useMemo,
  useEffect,
  useState,
} from "react";
import { get } from "lodash";
import { DEFAULT_TUTORIAL_STATUSES } from "components/Tutorial/consts/tutorialConsts";

const TutorialContext = createContext(null);

export const TutorialProvider = ({ children }) => {
  const [tutorialStatuses, setTutorialStatuses] = useState(null);
  const { user, updateUser } = useAuth();

  // instantiate tutorialStatuses or populate user's initial statuses
  useEffect(() => {
    if (!user) {
      return;
    }

    const tutorialStatuses = user?.tutorialStatuses;

    if (tutorialStatuses) {
      setTutorialStatuses(tutorialStatuses);
    } else {
      updateUser(() => ({
        tutorialStatuses: DEFAULT_TUTORIAL_STATUSES,
      }));
    }
  }, [updateUser, user]);
  const updateTutorial = useCallback(
    (tutorialId, status) => {
      return updateUser((doc) => {
        const tutorialStatuses = doc.data().tutorialStatuses || {};
        const newTutorialStatuses = {
          ...tutorialStatuses,
          [tutorialId]: status,
        };
        setTutorialStatuses(newTutorialStatuses);
        return {
          tutorialStatuses: newTutorialStatuses,
        };
      });
    },
    [updateUser]
  );

  const tutorialStatusesReady = useMemo(
    () => Boolean(user) && Boolean(tutorialStatuses),
    [tutorialStatuses, user]
  );
  const getTutorialStatus = useCallback(
    (tutorialId) => get(tutorialStatuses, [tutorialId], 0),
    [tutorialStatuses]
  );

  const incrementTutorialStatus = useCallback(
    (tutorialId) => {
      const currentStatus = getTutorialStatus(tutorialId);
      updateTutorial(tutorialId, currentStatus + 1);
    },
    [getTutorialStatus, updateTutorial]
  );

  const decrementTutorialStatus = useCallback(
    (tutorialId) => {
      const currentStatus = getTutorialStatus(tutorialId);
      const newStatus = Math.max(currentStatus - 1, 0);
      if (newStatus === currentStatus) {
        return false;
      }
      updateTutorial(tutorialId, currentStatus - 1);
      return true;
    },
    [getTutorialStatus, updateTutorial]
  );

  const completeTutorial = useCallback(
    (tutorialId) => {
      updateTutorial(tutorialId, -1);
    },
    [updateTutorial]
  );

  const value = useMemo(
    () => ({
      tutorialStatusesReady,
      getTutorialStatus,
      decrementTutorialStatus,
      incrementTutorialStatus,
      updateTutorial,
      completeTutorial,
    }),
    [
      tutorialStatusesReady,
      getTutorialStatus,
      decrementTutorialStatus,
      incrementTutorialStatus,
      updateTutorial,
      completeTutorial,
    ]
  );

  return (
    <TutorialContext.Provider value={value}>
      {children}
    </TutorialContext.Provider>
  );
};

export const useTutorial = (id) => {
  const context = useContext(TutorialContext);
  if (!context) {
    throw new Error(`useTutorial must be used within a TutorialProvider`);
  }
  return context;
};
