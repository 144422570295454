import { KEYS } from "utils/listeners";
import { useKeyupListener } from "./useKeyupListener";

export const useEscapeBlur = () => {
  useKeyupListener((e) => {
    if (e.key === KEYS.ESCAPE && document.activeElement !== document.body) {
      document.activeElement.blur();
    }
  }, []);
};
