import React, { useEffect, useMemo } from "react";
import styles from "./introTutorial.module.scss";
import {
  charactersButtonId,
  compendiumButtonId,
  compendiumModalButtonId,
  compendiumSearchId,
  diceRollerButtonId,
  generateInviteButtonId,
  groupsButtonId,
  reloadButtonId,
  selectItemDropdownId,
  settingsButtonId,
  shipsButtonId,
  gameMasterButtonId,
  ChatButtonId,
  getPageAddItemButtonId,
} from "constants/portalIds";
import { useCurrentGroup } from "hooks/useCurrentGroup";
import { usePage } from "hooks/usePage";
import {
  CHARACTERS_PATH,
  CHARACTER_WIZARD_PATH,
} from "components/Characters/constants/charactersConsts";
import { COMPENDIUM_PATHS } from "components/Compendium/consts/routeConsts";
import { TutorialTooltip } from "./TutorialTooltip";
import { GROUPS_PATH } from "components/Groups/constants/groupsConsts";
import { TutorialModal } from "./TutorialModal";
import { TUTORIAL_STEP_TYPES } from "./consts/tutorialConsts";
import { useNavigator } from "components/Router/NavigatorContext";

export const IntroTutorial = ({
  portalProps,
  status,
  incrementStatus,
  updateStatus,
  complete,
  setIsModalOpen,
}) => {
  const { groupDoc } = useCurrentGroup();
  const [page, id] = usePage();
  const { navigate } = useNavigator();
  const tooltipSteps = useMemo(() => {
    const usersCount = groupDoc?.users?.length || 0;
    const invitedToGroup = usersCount > 1;
    return [
      {
        type: TUTORIAL_STEP_TYPES.MODAL,
        title: "Welcome to the Wildsea!",
        subtitle: (
          <>
            {"Let's quickly walk through the core features of the app"}
            <br />
            {"so you can get started with your first campaign."}
          </>
        ),
        buttonLabel: "LET'S BEGIN",
        className: styles["splash"],
      },
      {
        type: TUTORIAL_STEP_TYPES.TOOLTIP,
        sourceId: getPageAddItemButtonId(GROUPS_PATH),
        position: "left-start",
        width: 400,
        shouldRender: ({ page }) => page === GROUPS_PATH,
        children: `These "plus" buttons in the header allow you to create new things based on what page you're on.`,
      },
      {
        type: TUTORIAL_STEP_TYPES.TOOLTIP,
        sourceId: getPageAddItemButtonId(GROUPS_PATH),
        position: "left-start",
        width: 500,
        hideNext: !invitedToGroup,
        shouldIncrementStep: invitedToGroup ? null : ({ groupDoc }) => groupDoc,
        shouldRender: ({ page }) => page === GROUPS_PATH,
        children: invitedToGroup
          ? `We're currently on the "Groups" page. You've already been invited into a group, but you can use this button if you ever want to create your own group in the future!`
          : `We're currently on the "Groups" page. Go ahead and click this button to name and create your first group!`,
      },
      {
        type: TUTORIAL_STEP_TYPES.TOOLTIP,
        sourceId: groupsButtonId,
        position: "bottom-end",
        width: 510,
        shouldRender: ({ page }) => page === GROUPS_PATH,
        children: `The "Groups" page lists things like characters and ships that your group has made and also lists all the users who have joined.`,
      },
      {
        type: TUTORIAL_STEP_TYPES.TOOLTIP,
        sourceId: generateInviteButtonId,
        position: "top",
        width: 400,
        shouldRender: ({ page }) => page === GROUPS_PATH,
        children: `If you want to invite others to join this group, you can click this button to generate an invitation link.`,
      },
      {
        type: TUTORIAL_STEP_TYPES.TOOLTIP,
        sourceId: charactersButtonId,
        position: "bottom",
        width: 410,
        hideNext: true,
        shouldIncrementStep: ({ page }) => page === CHARACTERS_PATH,
        shouldRender: ({ page }) => page !== CHARACTERS_PATH,
        children: `Click this button to switch to the "Characters" page.`,
      },
      {
        type: TUTORIAL_STEP_TYPES.TOOLTIP,
        sourceId: getPageAddItemButtonId(CHARACTERS_PATH),
        position: "left-start",
        width: 550,
        skippable: true,
        shouldIncrementStep: ({ page, id }) =>
          id && id !== CHARACTER_WIZARD_PATH && page === CHARACTERS_PATH,
        shouldRender: ({ page }) => page === CHARACTERS_PATH,
        children: `You can click this button to make a new character. You will have the option to go through our Guided Character Creator or just create a completely blank character. You can skip this step if you're not ready to make your first character yet.`,
      },
      {
        type: TUTORIAL_STEP_TYPES.TOOLTIP,
        sourceId: charactersButtonId,
        position: "bottom",
        width: 430,
        shouldRender: ({ page }) => page === CHARACTERS_PATH,
        children: `The "Characters" Page mimics the official Wildsea character sheet. It has everything you'll need to update and track as your character journeys through the Wildsea.`,
      },
      {
        type: TUTORIAL_STEP_TYPES.TOOLTIP,
        sourceId: shipsButtonId,
        position: "bottom-end",
        width: 540,
        shouldRender: ({ page }) => page === CHARACTERS_PATH,
        children: `This button takes you to the "Ships" page. Just like the "Characters" page, you can update and manage your crew's ships here.`,
      },
      {
        type: TUTORIAL_STEP_TYPES.TOOLTIP,
        sourceId: gameMasterButtonId,
        position: "bottom-end",
        width: 540,
        shouldRender: ({ page }) => page === CHARACTERS_PATH,
        children: `This button takes you to the "GMs" page. Your group's Firefly can use this page to help manage your campaign.`,
      },
      {
        type: TUTORIAL_STEP_TYPES.TOOLTIP,
        sourceId: selectItemDropdownId,
        position: "bottom",
        width: 410,
        shouldIncrementStep: ({ page, id }) => !id && page === CHARACTERS_PATH,
        shouldRender: ({ page }) => page === CHARACTERS_PATH,
        children: `You can use this dropdown to switch between all of your groups, characters, and ships while you're on the corresponding page.`,
      },
      {
        type: TUTORIAL_STEP_TYPES.TOOLTIP,
        sourceId: compendiumButtonId,
        position: "bottom-end",
        width: 400,
        hideNext: true,
        shouldIncrementStep: ({ page }) => page === COMPENDIUM_PATHS.BASE,
        shouldRender: ({ page }) => page !== COMPENDIUM_PATHS.BASE,
        children: `Click this button to switch to the "Compendium".`,
      },
      {
        type: TUTORIAL_STEP_TYPES.TOOLTIP,
        sourceId: compendiumButtonId,
        position: "bottom-end",
        width: 490,
        shouldRender: ({ page }) => page === COMPENDIUM_PATHS.BASE,
        children: `This is where you can access all the Wildsea resources from the rulebook to reference as you play through your campaign.`,
      },
      {
        type: TUTORIAL_STEP_TYPES.TOOLTIP,
        sourceId: compendiumSearchId,
        position: "bottom",
        width: 540,
        shouldRender: ({ page }) => page === COMPENDIUM_PATHS.BASE,
        children: `If you're looking for something specific, you can use this search bar to find all the information that matches what you're looking for.`,
      },
      {
        type: TUTORIAL_STEP_TYPES.TOOLTIP,
        sourceId: charactersButtonId,
        position: "bottom",
        width: 410,
        hideNext: true,
        shouldIncrementStep: ({ page }) => page === CHARACTERS_PATH,
        shouldRender: ({ page }) => page !== CHARACTERS_PATH,
        children: `Let's take a look at some more useful features back on the "Characters" page.`,
      },
      {
        type: TUTORIAL_STEP_TYPES.TOOLTIP,
        sourceId: compendiumModalButtonId,
        position: "left-end",
        width: 410,
        shouldRender: ({ page }) => page !== COMPENDIUM_PATHS.BASE,
        children: `You can also access the compendium as a modal at any time through this button.`,
      },
      {
        type: TUTORIAL_STEP_TYPES.TOOLTIP,
        sourceId: diceRollerButtonId,
        position: "left-end",
        width: 450,
        shouldRender: ({ page }) => page !== COMPENDIUM_PATHS.BASE,
        children: `You can use this button if you need to roll dice during your sessions. The results will be automatically broadcasted to everyone in your group, and it will even highlight your duplicates!`,
      },
      {
        type: TUTORIAL_STEP_TYPES.TOOLTIP,
        sourceId: ChatButtonId,
        position: "left-end",
        width: 450,
        shouldRender: ({ page }) => page !== COMPENDIUM_PATHS.BASE,
        children: `This button opens the chat bar where you can send messages to other people in your group and view the previous chat history. You can start typing messages at any time by using the ENTER key.`,
      },
      {
        type: TUTORIAL_STEP_TYPES.TOOLTIP,
        sourceId: reloadButtonId,
        position: "bottom-end",
        width: 480,
        shouldRender: ({ page }) => page !== COMPENDIUM_PATHS.BASE,
        children: `This button lets you refresh the data on the current page. This should happen automatically, but if it's ever behind, you can use this button to manually refresh.`,
      },
      {
        type: TUTORIAL_STEP_TYPES.TOOLTIP,
        sourceId: settingsButtonId,
        position: "bottom-end",
        width: 450,
        shouldRender: ({ page }) => page !== COMPENDIUM_PATHS.BASE,
        children: `Lastly, this button opens the settings, where you can change the app's themes, change your account settings, view hotkeys, or sign out of your account`,
      },
      {
        type: TUTORIAL_STEP_TYPES.MODAL,
        isEnd: true,
        title: "You're ready to dive into the Wildsea!",
        subtitle: (
          <>
            {
              "There's lots more features and tools to discover on every page, but you"
            }
            <br />
            {
              "should be well-equipped to explore the Wildsea and the rest of the app."
            }
            <br />
            <br />
            {
              'If you want to view this tutorial again, click the "Reset Intro" button'
            }
            <br />
            {"in the Settings Drawer"}
          </>
        ),
        buttonLabel: "START PLAYING",
        className: styles["end"],
      },
    ];
  }, [groupDoc]);

  const stepProps = tooltipSteps[status];

  const { type, shouldIncrementStep, shouldRender, isEnd, ...componentProps } =
    stepProps || {};

  useEffect(() => {
    if (shouldIncrementStep && shouldIncrementStep({ groupDoc, page, id })) {
      incrementStatus();
    }
  }, [incrementStatus, shouldIncrementStep, groupDoc, page, id]);

  useEffect(() => {
    setIsModalOpen(type === TUTORIAL_STEP_TYPES.MODAL);
  }, [type, setIsModalOpen]);

  // on mount, ensure user is on groups page
  useEffect(() => {
    if (status === 0) {
      navigate({ to: `/${GROUPS_PATH}` });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  const shouldRenderProps = { groupDoc, page, id };
  const commonProps = {
    onButtonClick: isEnd ? complete : incrementStatus,
    onSkipToEnd: () => updateStatus(tooltipSteps.length - 1),
    shouldRender: shouldRender && shouldRender(shouldRenderProps),
    shouldRenderProps,
  };

  return (
    <>
      {type === TUTORIAL_STEP_TYPES.TOOLTIP && stepProps && (
        <TutorialTooltip {...componentProps} {...commonProps} />
      )}
      {type === TUTORIAL_STEP_TYPES.MODAL && stepProps && (
        <TutorialModal
          {...componentProps}
          {...commonProps}
          portalProps={portalProps}
        />
      )}
    </>
  );
};
