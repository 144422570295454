import { COMPENDIUM_PATHS } from "components/Compendium/consts/routeConsts";
import { useLocalStorage } from "hooks/useLocalStorage";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useLocation, useMatch } from "@tanstack/react-router";

export const COMPENDIUM_IFRAME_LOCAL_STORAGE_PATH = "compendiumIframePath";
export const IFrameContext = createContext({});
export const IFrameProvider = ({ children }) => {
  const location = useLocation();
  const [isIFrame] = useState(location.search?.isIFrame);
  const path = useMatch(
    `/${COMPENDIUM_PATHS.BASE}/${COMPENDIUM_PATHS.ENTRY}/:path`
  )?.params?.path;

  const { setStorageValue } = useLocalStorage(
    COMPENDIUM_IFRAME_LOCAL_STORAGE_PATH
  );

  useEffect(() => {
    if (isIFrame) {
      setStorageValue(path || "");
    }
  }, [isIFrame, path, setStorageValue]);

  const value = useMemo(() => ({ isIFrame }), [isIFrame]);

  return (
    <IFrameContext.Provider value={value}>{children}</IFrameContext.Provider>
  );
};

export const useIFrame = () => {
  return useContext(IFrameContext);
};
