import { useCallback, useEffect, useMemo } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { identity } from "lodash/fp";
import { isFunction } from "lodash";

const BASE_LOCAL_STORAGE_QUERY_KEY = "local-storage";

export const useLocalStorage = (
  key,
  { forceRefresh, serialize = identity, deserialize = identity } = {}
) => {
  const queryClient = useQueryClient();
  const accessors = useMemo(() => {
    const getItem = () => deserialize(localStorage.getItem(key));
    return {
      getItem,
      setItem: (key, newValue) =>
        localStorage.setItem(
          key,
          serialize(isFunction(newValue) ? newValue(getItem()) : newValue)
        ),
    };
  }, [key, serialize, deserialize]);

  const { data, refetch } = useQuery({
    queryKey: [BASE_LOCAL_STORAGE_QUERY_KEY, key],
    queryFn: () => {
      return accessors.getItem(key);
    },
    placeholderData: accessors.getItem(key),
  });

  useEffect(() => {
    if (forceRefresh) {
      refetch();
    }
  }, [forceRefresh, refetch]);

  const setData = useCallback(
    (newValue) => {
      accessors.setItem(key, newValue);
      queryClient.setQueryData([BASE_LOCAL_STORAGE_QUERY_KEY, key], newValue);

      return newValue;
    },
    [key, queryClient, accessors]
  );

  const update = useCallback(
    (updater) => {
      const newValue = updater(accessors.getItem(key));

      accessors.setItem(key, newValue);
      queryClient.setQueryData([BASE_LOCAL_STORAGE_QUERY_KEY, key], updater);

      return newValue;
    },
    [key, queryClient, accessors]
  );

  const remove = useCallback(() => {
    localStorage.removeItem(key);
    queryClient.setQueryData([BASE_LOCAL_STORAGE_QUERY_KEY, key], () => null);
  }, [key, queryClient]);

  const getCurrentValue = useCallback(() => {
    return accessors.getItem(key);
  }, [key, accessors]);

  return {
    storageValue: data,
    setStorageValue: setData,
    updateStorageValue: update,
    removeStorageValue: remove,
    getCurrentStorageValue: getCurrentValue,
    refetch,
  };
};

export const useLocalStorageState = (...args) => {
  const { storageValue, setStorageValue } = useLocalStorage(...args);

  return [storageValue, setStorageValue];
};
export const useLocalStorageValue = (...args) => {
  const { storageValue } = useLocalStorage(...args);

  return storageValue;
};
export const useLocalStorageSetter = (...args) => {
  const { setStorageValue } = useLocalStorage(...args);

  return setStorageValue;
};
export const useLocalStorageUpdater = (...args) => {
  const { updateStorageValue } = useLocalStorage(...args);

  return updateStorageValue;
};
export const useLocalStorageRemover = (...args) => {
  const { removeStorageValue } = useLocalStorage(...args);

  return removeStorageValue;
};
export const useLocalStorageGetter = (...args) => {
  const { getCurrentStorageValue } = useLocalStorage(...args);

  return getCurrentStorageValue;
};

const serializeJSON = (data) => JSON.stringify(data);
const deserializeJSON = (data) => JSON.parse(data);
export const JSONSerializers = {
  serialize: serializeJSON,
  deserialize: deserializeJSON,
};

const serializeBool = (data) => data.toString();
const deserializeBool = (data, key) =>
  data === "true" ? true : data === "false" ? false : undefined;
export const BoolSerializers = {
  serialize: serializeBool,
  deserialize: deserializeBool,
};
