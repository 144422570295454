import { useEffect, useRef, useState } from "react";
import classNames from "classnames/bind";
import styles from "./chevron.module.scss";
import { ChevronDown } from "lucide-react";

const classNameBuilder = classNames.bind(styles);

export const Chevron = ({ opened, className, ...rest }) => {
  const [openedCount, setOpenedCount] = useState(opened ? 1 : 0);
  const prevOpenedRef = useRef(opened);
  useEffect(() => {
    if (opened !== prevOpenedRef.current) {
      setOpenedCount((v) => v + 1);
      prevOpenedRef.current = opened;
    }
  }, [opened]);
  return (
    <ChevronDown
      className={classNameBuilder("chevron", className)}
      style={{ "--rotation": openedCount * 180 + "deg" }}
      {...rest}
    />
  );
};
