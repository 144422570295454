import { MessageSquareText } from "lucide-react";
import styles from "./changeLog.module.scss";
import { BULLET_LIST_ITEM_TYPE } from "components/Common/List/bulletListConsts";
import { stableLink } from "constants/contactInfo";

export const versions = [
  {
    version: "1.2.2",
    date: "2024-08-25",
    changes: [
      {
        type: BULLET_LIST_ITEM_TYPE.PATREON,
        text: "Added ability to export sheets to JSON.",
        items: [
          "Try it out by clicking CTRL + S while on any character, ship, or GM.",
          "You can find this and other useful keybinds at the bottom of the settings drawer.",
        ],
      },
    ],
  },
  {
    version: "1.2.1",
    date: "2024-08-21",
    changes: [
      "Added UI in dice roller for adding cut to a roll.",
      "Re-organized settings drawer.",
      "Added several settings to let users customize their dice, cut, and chat behavior.",
    ],
  },
  {
    version: "1.2.0",
    date: "2024-08-12",
    changes: [
      <span>
        Added ability to ping certain sections of sheets into the chat! Look for
        the <MessageSquareText width={15} height={15} /> icon in your sheets!
      </span>,
      "Added feature to allow new accounts to set up their profile settings immediately after creation",
      {
        type: BULLET_LIST_ITEM_TYPE.IMPORTANT,
        text: "Added new login method: email + password ",
        items: [
          `If you'd like to add a password to an existing account, click the "Forgot password?" link on the sign-in page and follow the on-screen instructions.`,
          `gmail-based "email + password" accounts will still be able to use the "Sign in with Google" button`,
          {
            type: BULLET_LIST_ITEM_TYPE.WARNING,
            text: `If you use a fake email, we will not be able to reset your password!`,
          },
          <span>
            If you run into any login issues, please contact{" "}
            <u>charsmithapp@gmail.com</u>
          </span>,
        ],
      },
    ],
  },
  {
    version: "1.1.1",
    date: "2024-08-12",
    changes: ["Added 2 new themes: Pink (Dark) and Pink (Light)"],
  },
  {
    version: "1.1.0",
    date: "2024-08-09",
    changes: [
      {
        type: BULLET_LIST_ITEM_TYPE.WARNING,
        text: (
          <>
            <span>
              Updated Site Routing Architecture - while big for the dev team,
              you will hopefully notice no changes in behavior
            </span>
            <br />
            <br />
            <div>
              If you notice any issues, please send an email to{" "}
              <u>charsmithapp@gmail.com</u>,
            </div>
          </>
        ),
      },
      {
        type: BULLET_LIST_ITEM_TYPE.IMPORTANT,
        text: (
          <>
            <span>
              We've created a 'stable' version of the app with the last stable
              version of the app. If you are unable to use the app due to an
              update, try using{" "}
              <a href={stableLink} className={styles["link"]}>
                {stableLink}
              </a>
            </span>
            <br />
            <br />
            <div>The stable build of the site will be on version 1.0.2</div>
          </>
        ),
      },
      {
        type: BULLET_LIST_ITEM_TYPE.PATREON,
        text: "Added Mute Button - our first patreon request! 🎉",
      },
      "Bugfixes",
    ],
  },
  {
    version: "1.0.2",
    date: "2024-07-22",
    changes: [
      "Added ability to mark ratings in the ship sheet",
      'Added "By Firefly Light" logo to footer',
      "Added confirmation modal when deleting users from groups",
      "Added ability to remove yourself from a group, even if you're not the owner (available on the Groups page)",
      "Added confirm button when joining a group",
      'Added "Clear local storage and refresh" button on error page to help stuck users',
      "Bugfixes",
    ],
  },
  {
    version: "1.0.1",
    date: "2024-06-24",
    changes: [
      'Added this "Changelog" 🙂',
      'Fixed functionality of "ratings" in the ship sheet to match how they should be used in the rules',
      "Added ability to select Wildsailor portraits in the User section of the Settings Drawer",
      "Bugfixes",
    ],
  },
  {
    version: "1.0.0",
    date: "2024-06-19",
    changes: ["Initial launch! 🎉"],
  },
];

export const currentVersion = versions[0].version;
