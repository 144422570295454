import React, { useState } from "react";
import { useMeasure, useMount } from "react-use";

export const Circle = ({
  className,
  circleClassName,
  progress = 1,
  ...rest
}) => {
  //const initialRef = useRef(true);
  const [initialized, setInitialized] = useState(progress === 1);
  const [ref, { height }] = useMeasure();
  // i love geography
  const strokeDasharray = Math.PI * height;
  const strokeDashoffset = Math.PI * height * (1 - progress);
  useMount(() => {
    // svgs apparently always initialize with a strokeDashoffset of 0, so it triggers
    // an initial animation when it's non-0, so we override the animation for the first frame
    const id = setTimeout(() => setInitialized(true), 100);
    return () => clearTimeout(id);
  });
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className={className}
      strokeDasharray={strokeDasharray}
      strokeDashoffset={strokeDashoffset}
      {...rest}
    >
      <circle
        ref={ref}
        style={
          !initialized ? { transition: "none", color: "transparent" } : null
        }
        className={circleClassName}
        cx="12"
        cy="12"
        r="10"
      />
    </svg>
  );
};
