import { HelpTooltip } from "components/Common/HelpTooltip";
import styles from "./settingsDrawerToggle.module.scss";
import { AlertCircle, ToggleLeft, ToggleRight } from "lucide-react";

export const SettingsDrawerToggle = ({
  onToggle,
  value,
  onText,
  offText,
  helpTooltip,
  warning,
  label,
}) => {
  return (
    <div className={styles["toggle-container"]}>
      <div className={styles["label-container"]}>
        <div className={styles["label"]}>{label}</div>
        {helpTooltip && (
          <HelpTooltip position={"top-end"} width="300px">
            {helpTooltip}
          </HelpTooltip>
        )}
      </div>
      {warning && (
        <div className={styles["warning"]}>
          <AlertCircle /> {warning}
        </div>
      )}
      <div onClick={onToggle} className={styles["toggle"]}>
        {value ? (
          <>
            <ToggleRight className={styles["good"]} />
            {onText}
          </>
        ) : (
          <>
            <ToggleLeft className={styles["bad"]} />
            {offText}
          </>
        )}
      </div>
    </div>
  );
};
