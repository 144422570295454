import { createContext, useContext, useMemo } from "react";
import { getFirestore } from "firebase/firestore";

const DatabaseContext = createContext(null);

export const DatabaseContextProvider = ({ children }) => {
  const db = useMemo(() => getFirestore(), []);
  return (
    <DatabaseContext.Provider value={db}>{children}</DatabaseContext.Provider>
  );
};

export const useDatabase = () => {
  return useContext(DatabaseContext);
};
