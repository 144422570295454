import { shipsButtonId } from "constants/portalIds";
import { getDocumentGroupHeaderPathFunc } from "lib/routeUtils";
import { LAST_SHIP_KEY, SHIPS_PATH } from "./shipsConsts";
import { Anchor } from "components/Common/Icons/Anchor";

export const shipRouteAppHeaderConfig = {
  path: SHIPS_PATH,
  staticData: {
    label: "Ships",
    icon: ({ hovered }) => <Anchor hovered={hovered} />,
    buttonId: shipsButtonId,
    requiresGroup: true,
    getHeaderPath: getDocumentGroupHeaderPathFunc({
      localStorageKey: LAST_SHIP_KEY,
      path: SHIPS_PATH,
    }),
  },
};
