import React, { memo, useEffect, useMemo } from "react";
import styles from "./appHeader.module.scss";
import { Button } from "../Common/Button";
import { Anchor, Cog, RefreshCw } from "lucide-react";
import { SettingsDrawer } from "./SettingsDrawer";
import { Header } from "../Common/Header";
import { Divider } from "../Common/Divider";
import { useNavigate } from "@tanstack/react-router";
import { useAppHeader } from "../../contexts/AppHeaderContext";
import { Link } from "../Common/Link";
import { useAuth } from "../../contexts/AuthContext";
import { useGroupId } from "../../hooks/useGroupId";
import classnames from "classnames/bind";
import { useIFrame } from "contexts/IframeContext";
import { usePage } from "hooks/usePage";
import { reloadButtonId, settingsButtonId } from "constants/portalIds";
import { IFrameAppHeader } from "./IFrameAppHeader";
import { useKeyupListener } from "hooks/listeners/useKeyupListener";
import { findIndex } from "lodash";
import { getWrap } from "lib/arrayUtils";
import { HoverableButton } from "components/Common/HoverableButton";
import { ScaleTransition } from "components/Common/Transition/ScaleTransition";
import { usePageConfig } from "hooks/usePageConfig";
import { characterRouteAppHeaderConfig } from "components/Characters/constants/characterRouteAppHeaderConfig";
import { shipRouteAppHeaderConfig } from "components/Ships/constants/shipRouteAppHeaderConfig";
import { groupRouteAppHeaderConfig } from "components/Groups/constants/groupRouteAppHeaderConfig";
import { gameMasterAppHeaderConfig } from "components/GameMasters/constants/gameMasterAppHeaderConfig";
import { compendiumAppHeaderConfig } from "components/Compendium/consts/compendiumAppHeaderConfig";

const classNameBuilder = classnames.bind(styles);

const appHeaderConfigs = [
  groupRouteAppHeaderConfig,
  characterRouteAppHeaderConfig,
  shipRouteAppHeaderConfig,
  gameMasterAppHeaderConfig,
  compendiumAppHeaderConfig,
];

export const AppHeader = memo(() => {
  const { user, shouldSetupUser } = useAuth();
  const groupId = useGroupId();
  const [base] = usePage();
  const {
    portalRef,
    refresh,
    isPortalReady,
    setIsPortalReady,
    settingsDrawer,
  } = useAppHeader();
  const { portalProps: settingsDrawerPortalProps, ...settingsDrawerProps } =
    settingsDrawer;
  const { setIsPortalComponentRendered: setIsSettingDrawerRendered } =
    settingsDrawerPortalProps;

  const { isIFrame } = useIFrame();
  const navigate = useNavigate();

  const [shouldHideHeaderPortal, shouldHideTitle] = usePageConfig([
    "shouldHideHeaderPortal",
    "shouldHideTitle",
  ]);

  const setRef = (ref) => {
    if (!isPortalReady) {
      setIsPortalReady(true);
    }
    portalRef.current = ref;
  };
  const shouldShowButtons = Boolean(user) && !shouldSetupUser;

  const pageEntries = useMemo(() => {
    const base = appHeaderConfigs.filter(
      (config) => !config.staticData?.shouldHideNavButton
    );

    // if there's NOT a groupId, filter the buttons that require a group
    if (!groupId) {
      return base.filter((config) => !config.staticData?.requiresGroup);
    }

    return base;
  }, [groupId]);

  useEffect(() => {
    if (!shouldShowButtons) {
      setIsPortalReady(false);
    }
  }, [shouldShowButtons, setIsPortalReady]);

  useKeyupListener(
    (e) => {
      if (
        (e.code === "ArrowLeft" || e.code === "ArrowRight") &&
        e.altKey &&
        e.shiftKey
      ) {
        const curIndex = findIndex(
          pageEntries,
          (config) => config?.path === base
        );

        if (curIndex !== -1) {
          const newPage = getWrap(
            pageEntries,
            curIndex,
            e.code === "ArrowLeft" ? -1 : 1
          );

          newPage?.path && navigate({ to: `/${newPage?.path}` });
        }
      }
    },
    [navigate, base, pageEntries]
  );

  const headerPortal = (
    <div className={styles["portal"]} id="header-portal" ref={setRef} />
  );

  if (isIFrame) {
    return <IFrameAppHeader headerPortal={headerPortal} />;
  }

  let title = "The Wildsea";
  let subtitle = "Digital Character Sheet";

  if (shouldHideTitle) {
    title = "";
    subtitle = "";
  }

  return (
    <Header
      title={title}
      subtitle={subtitle}
      titleIcon={<Anchor />}
      titleIconClassName={styles["title-icon"]}
      className={styles["header"]}
      titleContainerClassName={styles["title-container"]}
      titleClassName={styles["title"]}
      subtitleClassName={styles["subtitle"]}
    >
      {shouldShowButtons ? (
        <div className={classNameBuilder("buttons")}>
          {!shouldHideHeaderPortal ? (
            <>
              {headerPortal}
              <Divider
                className={classNameBuilder("divider", "hide-when-small")}
                white
              />
            </>
          ) : null}
          <div className={styles["page-entries"]}>
            {pageEntries.map((config) => {
              const { path, staticData = {} } = config;
              const { getHeaderPath, icon: Icon, label, buttonId } = staticData;
              const selected = base === path;
              const linkParams = getHeaderPath ? getHeaderPath() : {};

              return (
                <Link key={path} to={`/${path}`} {...linkParams}>
                  <HoverableButton
                    id={buttonId}
                    dark
                    icon
                    large
                    title={label}
                    selected={selected}
                    className={styles["page-button"]}
                    disableHover={selected}
                  >
                    {({ hovered: hoveredProp }) => {
                      const hovered = hoveredProp && !selected;
                      return (
                        <ScaleTransition isScaled={hovered}>
                          <Icon hovered={hovered} />
                        </ScaleTransition>
                      );
                    }}
                  </HoverableButton>
                </Link>
              );
            })}
            {pageEntries.length > 0 && (
              <Divider className={styles["divider"]} white />
            )}
            <Button
              id={reloadButtonId}
              dark
              icon
              large
              rotation
              onClick={(e) => refresh(e)}
              disabled={!refresh}
              title={"Refresh"}
            >
              <RefreshCw />
            </Button>
            <Button
              id={settingsButtonId}
              dark
              icon
              large
              rotation
              onClick={() => setIsSettingDrawerRendered(true)}
              title={"Settings"}
            >
              <Cog />
            </Button>
          </div>
          <div className={styles["helper-buttons"]}></div>
          <SettingsDrawer
            portalProps={settingsDrawerPortalProps}
            {...settingsDrawerProps}
          />
        </div>
      ) : null}
    </Header>
  );
});
