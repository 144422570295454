import { KEYS } from "utils/listeners";
import { useKeyupListener } from "./useKeyupListener";

export const useEnterSubmit = (onSubmit) => {
  useKeyupListener(
    (e) => {
      if (e.key === KEYS.ENTER) {
        onSubmit();
      }
    },
    [onSubmit]
  );
};
