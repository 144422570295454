import { useCallback, useState } from "react";
import { Button } from "components/Common/Button";
import styles from "./baseAccountForm.module.scss";
import { EMAIL_SIGN_IN_ERRORS, getErrorLabel } from "./errorConsts";
import { DIRECTION, Divider } from "components/Common/Divider";
import { TriangleAlert } from "lucide-react";
import { Loader } from "components/Common/Loader";
import { Input } from "components/Common/Input";
import { useEnterSubmit } from "hooks/listeners/useEnterSubmit";

export const BaseAccountForm = ({
  error,
  submitLabel,
  onSubmit: onSubmitProp,
  googleButton,
  onGoogleClick,
  children,
}) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const onSubmit = useCallback(() => {
    setLoading(true);
    onSubmitProp(email, password).finally(() => setLoading(false));
  }, [email, password, onSubmitProp]);

  useEnterSubmit(() => onSubmit(email, password));

  return (
    <div className={styles["sign-in-methods"]}>
      <div className={styles["email-form"]}>
        {error && (
          <div className={styles["sign-in-err"]}>
            <TriangleAlert className={styles["icon"]} />
            {getErrorLabel(error, EMAIL_SIGN_IN_ERRORS)}
          </div>
        )}
        <Input
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email"
          value={email}
          className={styles["input"]}
        />
        <Input
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Password"
          type="password"
          value={password}
          className={styles["input"]}
        />
        {children}
        <Button
          onClick={() => onSubmit(email, password)}
          colorful
          className={styles["button"]}
          disabled={loading}
        >
          {loading ? <Loader /> : <div>{submitLabel}</div>}
        </Button>
      </div>
      <div className={styles["divider-container"]}>
        <Divider
          direction={DIRECTION.HORIZONTAL}
          className={styles["divider"]}
        />
        <span className={styles["or"]}>OR</span>

        <Divider
          direction={DIRECTION.HORIZONTAL}
          className={styles["divider"]}
        />
      </div>
      <Button
        className={styles["google-button"]}
        fitContent
        onClick={onGoogleClick}
      >
        {googleButton}
      </Button>
    </div>
  );
};
