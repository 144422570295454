import styles from "./pickOrUploadModal.module.scss";
import classnames from "classnames/bind";
import { useEffect, useState } from "react";
import { Modal } from "../Modal";
import { Button } from "../Button";
import { UploadAndSubmitImage } from "../UploadAndSubmitImage/UploadAndSubmitImage";
import { Image } from "../Image/Image";

const classNameBuilder = classnames.bind(styles);

export const PickOrUploadModal = ({
  portalProps,
  onSubmit,
  defaultImages,
  title = "Pick or Upload",
  contain,
  initialValue,
  imageContainerClassName,
  imageClassName,
  buttonClassName,
  imageProps,
  defaultName = "Default",
}) => {
  const { setIsPortalComponentRendered } = portalProps;
  const [newUrl, setNewUrl] = useState(initialValue);

  useEffect(() => {
    setNewUrl(initialValue);
  }, [initialValue]);

  return (
    <Modal
      portalProps={portalProps}
      closeDrawer={() => setIsPortalComponentRendered(false)}
      title={title}
      contentClassName={styles["container"]}
      onFooterCancel={() => setIsPortalComponentRendered(false)}
      onFooterSubmit={() => {
        setIsPortalComponentRendered(false);
        onSubmit(newUrl);
      }}
    >
      <div className={styles["title"]}>Upload your own</div>
      <UploadAndSubmitImage
        initialValue={newUrl}
        imageContainerClassName={imageContainerClassName}
        imageClassName={imageClassName}
        hideButtons
        onChange={setNewUrl}
        imageProps={imageProps}
        autoFocus
      />
      <div className={classNameBuilder("title", "or")}>OR</div>
      <div className={styles["title"]}>Pick A {defaultName}</div>
      <div className={styles["grid"]}>
        {defaultImages.map((p, i) => {
          return (
            <Button
              key={i}
              icon
              onClick={() => {
                setNewUrl(p);
              }}
              className={classNameBuilder("button", buttonClassName)}
            >
              <Image
                className={classNameBuilder("image", { contain })}
                src={p}
                alt="default"
              />
            </Button>
          );
        })}
      </div>
    </Modal>
  );
};
