export const THEMES = {
  PRIMARY: {
    DARK: "dark",
    LIGHT: "light",
  },
  SECONDARY: {
    RED: "red",
    ORANGE: "orange",
    YELLOW: "yellow",
    GREEN: "green",
    LIGHT_BLUE: "light-blue",
    DARK_BLUE: "dark-blue",
    PURPLE: "purple",
    DARK_PINK: "dark-pink",
    LIGHT_PINK: "light-pink",
  },
};
export const primaryThemesSet = new Set(Object.values(THEMES.PRIMARY));
export const secondaryThemesSet = new Set(Object.values(THEMES.SECONDARY));

export const NEUTRAL = "neutral";

export const primaryThemesMap = {
  [THEMES.PRIMARY.LIGHT]: { id: THEMES.PRIMARY.LIGHT, label: "Light" },
  [THEMES.PRIMARY.DARK]: { id: THEMES.PRIMARY.DARK, label: "Dark" },
};

export const primaryThemesList = Object.values(primaryThemesMap);

export const secondaryThemesMap = {
  [THEMES.SECONDARY.RED]: { id: THEMES.SECONDARY.RED, label: "Red" },
  [THEMES.SECONDARY.ORANGE]: { id: THEMES.SECONDARY.ORANGE, label: "Orange" },
  [THEMES.SECONDARY.YELLOW]: { id: THEMES.SECONDARY.YELLOW, label: "Yellow" },
  [THEMES.SECONDARY.GREEN]: { id: THEMES.SECONDARY.GREEN, label: "Green" },
  [THEMES.SECONDARY.LIGHT_BLUE]: {
    id: THEMES.SECONDARY.LIGHT_BLUE,
    label: "Blue (Light)",
  },
  [THEMES.SECONDARY.DARK_BLUE]: {
    id: THEMES.SECONDARY.DARK_BLUE,
    label: "Blue (Dark)",
  },
  [THEMES.SECONDARY.PURPLE]: { id: THEMES.SECONDARY.PURPLE, label: "Purple" },
  [THEMES.SECONDARY.LIGHT_PINK]: {
    id: THEMES.SECONDARY.LIGHT_PINK,
    label: "Pink (Light)",
  },
  [THEMES.SECONDARY.DARK_PINK]: {
    id: THEMES.SECONDARY.DARK_PINK,
    label: "Pink (Dark)",
  },
};

export const secondaryThemesList = Object.values(secondaryThemesMap);

export const CUSTOM_DICE_KEY = "CUSTOM";
export const DEFAULT_DICE_KEY = "DEFAULT";

export const diceThemesMap = {
  [DEFAULT_DICE_KEY]: { id: DEFAULT_DICE_KEY, label: "Use Secondary Theme" },
  ...secondaryThemesMap,
  [CUSTOM_DICE_KEY]: { id: CUSTOM_DICE_KEY, label: "Custom" },
};
export const diceThemesList = Object.values(diceThemesMap);
