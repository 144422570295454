import portrait0 from "./images/portrait0.png";
import portrait1 from "./images/portrait1.png";
import portrait2 from "./images/portrait2.png";
import portrait3 from "./images/portrait3.png";
import portrait4 from "./images/portrait4.png";
import portrait5 from "./images/portrait5.png";
import portrait6 from "./images/portrait6.png";
import portrait7 from "./images/portrait7.png";
import portrait8 from "./images/portrait8.png";
import portrait9 from "./images/portrait9.png";
import portrait10 from "./images/portrait10.png";
import portrait11 from "./images/portrait11.png";
import portrait12 from "./images/portrait12.png";
import portrait13 from "./images/portrait13.png";
import portrait14 from "./images/portrait14.png";
import portrait15 from "./images/portrait15.png";
import portrait16 from "./images/portrait16.png";
import portrait17 from "./images/portrait17.png";
import portrait18 from "./images/portrait18.png";
import portrait19 from "./images/portrait19.png";
import portrait20 from "./images/portrait20.png";
import portrait21 from "./images/portrait21.png";
import portrait22 from "./images/portrait22.png";
import portrait23 from "./images/portrait23.png";
import portrait24 from "./images/portrait24.png";
import portrait25 from "./images/portrait25.png";
import portrait26 from "./images/portrait26.png";
import portrait27 from "./images/portrait27.png";
import portrait28 from "./images/portrait28.png";
import portrait29 from "./images/portrait29.png";
import portrait30 from "./images/portrait30.png";

export const PORTRAIT_IMAGES = [
  portrait0,
  portrait1,
  portrait2,
  portrait3,
  portrait4,
  portrait5,
  portrait6,
  portrait7,
  portrait8,
  portrait9,
  portrait10,
  portrait11,
  portrait12,
  portrait13,
  portrait14,
  portrait15,
  portrait16,
  portrait17,
  portrait18,
  portrait19,
  portrait20,
  portrait21,
  portrait22,
  portrait23,
  portrait24,
  portrait25,
  portrait26,
  portrait27,
  portrait28,
  portrait29,
  portrait30,
];
