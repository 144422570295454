import React, { useState, useRef, useEffect, useMemo } from "react";
import { Button } from "./Button";
import { Search } from "./Icons";
import styles from "./dropdown.module.scss";
import classnames from "classnames/bind";
import { useMouseupListener } from "../../hooks/listeners/useMouseupListener";
import { useTransition } from "../../hooks/transition/useTransition";
import { Input } from "./Input";
import { SmallIcon } from "./SmallIcon";
import { includesKeys } from "../../hooks/listeners/useKeyupListener";
import { KEYS } from "../../utils/listeners";
import { LoaderWithText } from "./Loader";
import { isNil } from "lodash";
import { EMPTY_LIST } from "constants/defaults";
import { Chevron } from "./Shape/Chevron";

const classNameBuilder = classnames.bind(styles);

const Dropdown = ({
  items = EMPTY_LIST,
  selected,
  getKey = defaultGetKey,
  getItem,
  itemMap,
  setSelected,
  placeholder,
  containerClassName,
  placement = DROPDOWN_PLACEMENT.BOTTOM,
  dark,
  disabled,
  searchable = true,
  loading,
  loaderText = "Loading...",
  loadMoreEnabled,
  loadMoreText = "Load More",
  onLoadMore,
  id,
  getSearchLabel = getLabel,
  getBodyLabel,
  emptyText = "No options...",
  bodyClassName,
  bodyButtonProps,
  bodyContent,
  tailClassName,
}) => {
  const [opened, setOpened] = useState(false);
  const dropdownRef = useRef();
  const inputRef = useRef();
  const [search, setSearch] = useState("");

  useEffect(() => {
    setSearch("");
  }, [opened]);

  const filteredItems = useMemo(() => {
    if (!opened || !search) {
      return items;
    }

    const lowercaseSearch = search.toLowerCase();

    return items.filter((item) => {
      const label = getSearchLabel(item) || "";

      return label?.toLowerCase()?.includes(lowercaseSearch);
    });
  }, [getSearchLabel, items, opened, search]);

  if (!getItem) {
    if (itemMap) {
      getItem = (id) => itemMap[id];
    } else {
      getItem = (itemId) => items.find(({ id }) => id === itemId);
    }
  }
  return (
    <div
      id={id}
      ref={dropdownRef}
      className={classNameBuilder("container", containerClassName)}
    >
      <Body
        bodyClassName={bodyClassName}
        bodyContent={bodyContent}
        items={filteredItems}
        rawItems={items}
        selected={selected}
        setSelected={setSelected}
        getItem={getItem}
        getKey={getKey}
        placeholder={placeholder}
        opened={opened}
        setOpened={setOpened}
        dark={dark}
        disabled={disabled}
        search={search}
        setSearch={setSearch}
        searchable={searchable}
        inputRef={inputRef}
        getBodyLabel={getBodyLabel}
        bodyButtonProps={bodyButtonProps}
      />
      <Tail
        tailClassName={tailClassName}
        items={filteredItems}
        rawItems={items}
        selected={selected}
        getKey={getKey}
        setSelected={setSelected}
        opened={opened}
        setOpened={setOpened}
        placement={placement}
        search={search}
        searchable={searchable}
        dropdownRef={dropdownRef}
        inputRef={inputRef}
        loading={loading}
        loaderText={loaderText}
        loadMoreEnabled={loadMoreEnabled}
        loadMoreText={loadMoreText}
        onLoadMore={onLoadMore}
        emptyText={emptyText}
      />
    </div>
  );
};

const defaultGetKey = ({ id }) => id;

const Body = ({
  bodyClassName,
  bodyButtonProps,
  bodyContent: propsBodyContent,
  selected,
  getItem,
  placeholder,
  opened,
  setOpened,
  dark,
  disabled,
  search,
  setSearch,
  searchable,
  inputRef,
  getBodyLabel = getLabel,
}) => {
  const label =
    selected !== null ? getBodyLabel(getItem(selected)) : placeholder;
  const isTyping = searchable && opened;

  let bodyContent = (
    <>
      {isTyping ? (
        <>
          <div className={styles["input-container"]}>
            <SmallIcon>
              <Search />
            </SmallIcon>
            <Input
              autoFocus
              className={styles["input"]}
              onChange={(e) => setSearch(e.target.value)}
              value={search}
              ref={inputRef}
            />
          </div>
          <div />
        </>
      ) : (
        <div className={styles["label"]}>{label}</div>
      )}
      <Chevron opened={opened} />
    </>
  );

  if (propsBodyContent) {
    bodyContent = propsBodyContent;
  }
  return (
    <Button
      className={classNameBuilder("body", bodyClassName)}
      onClick={() => setOpened((v) => !v)}
      dark={dark}
      disabled={disabled}
      active={isTyping}
      spaceDisabled={isTyping}
      {...bodyButtonProps}
    >
      {bodyContent}
    </Button>
  );
};

const Tail = ({
  items,
  rawItems,
  selected,
  getKey,
  setSelected,
  opened,
  setOpened,
  placement,
  emptyText,
  searchable,
  dropdownRef,
  inputRef,
  loading,
  loaderText,
  loadMoreEnabled,
  loadMoreText,
  onLoadMore,
  tailClassName,
}) => {
  const [ref, isTransitionRendered, isTransitionEntering] =
    useTransition(opened);

  return isTransitionRendered ? (
    <div
      className={classNameBuilder("tail", tailClassName, placement, {
        opened: isTransitionEntering,
      })}
      ref={ref}
    >
      {loading ? (
        <Button
          className={classNameBuilder("item", "loader-container")}
          onClick={onLoadMore}
          disableMovement
        >
          <LoaderWithText className={styles["loader"]}>
            {loaderText}
          </LoaderWithText>
        </Button>
      ) : (
        <ItemsWrapper
          dropdownRef={dropdownRef}
          inputRef={inputRef}
          setOpened={setOpened}
          searchable={searchable}
          items={items}
          setSelected={setSelected}
          getKey={getKey}
        >
          {!items?.length && (
            <div className={classNameBuilder("item", "empty")}>{emptyText}</div>
          )}
          {items.map((item) => {
            const value = getKey(item);
            return (
              <Item
                key={value}
                item={item}
                value={value}
                selected={selected}
                setSelected={setSelected}
                setOpened={setOpened}
              />
            );
          })}
          {loadMoreEnabled && (
            <Button
              className={classNameBuilder("item", "load-more")}
              onClick={onLoadMore}
              disableMovement
            >
              {loadMoreText}
            </Button>
          )}
        </ItemsWrapper>
      )}
    </div>
  ) : null;
};

const ItemsWrapper = ({
  dropdownRef,
  inputRef,
  setOpened,
  searchable,
  items,
  setSelected,
  getKey,
  children,
}) => {
  useMouseupListener((e) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
      setOpened(false);
    }
  });
  useEffect(() => {
    const input = inputRef.current;
    if (!input) {
      return;
    }
    const listener = (e) => {
      if (includesKeys(e, KEYS.ESCAPE)) {
        return setOpened(false);
      }
      if (includesKeys(e, KEYS.ENTER) && searchable && items.length === 1) {
        const item = items[0];
        setSelected(getKey(item), item);
        return setOpened(false);
      }
    };
    input.addEventListener("keydown", listener);
    return () => input.removeEventListener("keydown", listener);
  }, [searchable, inputRef, items, setSelected, setOpened, getKey]);
  return children;
};

const Item = ({ item, value, selected, setSelected, setOpened }) => {
  const label = getLabel(item);
  const onClick = () => {
    setSelected(value, item);
    setOpened(false);
  };
  return (
    <Button
      className={classNameBuilder("item", {
        selected: value === selected,
      })}
      onClick={onClick}
      disableMovement
    >
      {label}
    </Button>
  );
};

const getLabel = (item) => (isNil(item?.label) ? item : item?.label);

const DROPDOWN_PLACEMENT = {
  TOP: "top",
  TOP_END: "top-end",
  BOTTOM: "bottom",
  BOTTOM_END: "bottom-end",
};

export { Dropdown, DROPDOWN_PLACEMENT };
