import React from "react";
import styles from "./anchor.module.scss";
import classnames from "classnames/bind";
import { Anchor as AnchorIcon } from "lucide-react";

const classNameBuilder = classnames.bind(styles);

export const Anchor = ({ className, hovered, ...rest }) => {
  return (
    <AnchorIcon
      className={classNameBuilder("container", { hovered }, className)}
      {...rest}
    />
  );
};
