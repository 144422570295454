import {
  ConfirmationModal,
  useConfirmationModal,
} from "components/Common/Portals/ConfirmationModal/ConfirmationModal";
import { Hand, ThumbsUp } from "lucide-react";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useLocation, useNavigate } from "@tanstack/react-router";
import styles from "./navigatorContext.module.scss";
import classnames from "classnames/bind";

const classNameBuilder = classnames.bind(styles);

export const NavigatorContext = createContext();

export const NavigatorProvider = ({ children }) => {
  const location = useLocation();
  const [dirty, rawSetDirty] = useState(0);
  const dirtyRef = useRef();
  dirtyRef.current = dirty;

  const modalProps = useConfirmationModal();
  const { openModal, confirmationModalController, portalProps } = modalProps;

  const incrementDirty = useCallback(() => {
    rawSetDirty((prev) => {
      const val = prev + 1;
      dirtyRef.current = val;
      return val;
    });
  }, []);
  const decrementDirty = useCallback(() => {
    rawSetDirty((prev) => {
      const val = Math.max(prev - 1, 0);
      dirtyRef.current = val;
      return val;
    });
  }, []);

  // TODO: Figure out why this logic doesn't work properly
  // // prevent unload if dirtyRef is positive
  // useEffect(() => {
  //   window.onbeforeunload = (e) =>
  //     dirtyRef.current > 0 ? e.preventDefault() : false;

  //   return () => (window.onbeforeunload = noop);
  // }, [openModal]);

  // // on route change, reset dirty to 0 just in case someone changed their value late
  useEffect(() => {
    rawSetDirty(0);
  }, [location]);
  useEffect(() => {
    const resolver = confirmationModalController.current;
    if (!dirty && resolver) {
      // mark the modal as closed -> then call resolver with true
      const timeout = window.setTimeout(() => {
        portalProps.setIsPortalComponentRendered(false);
        resolver(true);
      }, 1000);
      return () => window.clearTimeout(timeout);
    }
  }, [dirty, portalProps, confirmationModalController]);

  return (
    <NavigatorContext.Provider
      value={{ dirtyRef, incrementDirty, decrementDirty, openModal }}
    >
      <ConfirmationModal
        {...modalProps}
        title={dirty ? undefined : "Good to go!"}
        continueText={dirty ? undefined : "Continue"}
        continueClassName={classNameBuilder("continue-button", { dirty })}
        description={
          <div className={styles["description"]}>
            <div className={classNameBuilder("icon", { dirty })}>
              {dirty ? <Hand /> : <ThumbsUp />}
            </div>
            <span>
              {dirty
                ? "You have unsaved changes. Leaving will permanently delete these changes."
                : "Your changes have been saved successfully!"}
            </span>
          </div>
        }
      />
      {children}
    </NavigatorContext.Provider>
  );
};

export const useNavigator = () => {
  const { dirtyRef, openModal, ...rest } = useContext(NavigatorContext);
  const rawNavigate = useNavigate();

  const navigate = useCallback(
    async (...args) => {
      if (dirtyRef.current > 0) {
        const res = await openModal();
        if (!res) {
          return;
        }
      }
      rawNavigate(...args);
    },
    [dirtyRef, openModal, rawNavigate]
  );

  return {
    dirtyRef,
    navigate,
    rawNavigate,
    openModal,
    ...rest,
  };
};
