import { useState } from "react";

export const useDisabledHoverOnClick = ({ useIndex } = {}) => {
  const disabledValue = useIndex ? -1 : false;
  const [hoveredState, setHoveredState] = useState(disabledValue);
  const [clicked, setClicked] = useState(disabledValue);
  let hovered = hoveredState && !clicked;
  if (useIndex) {
    hovered = clicked !== hoveredState ? hoveredState : disabledValue;
  }
  const onMouseEnter = (index) => setHoveredState(useIndex ? index : true);
  const onMouseLeave = () =>
    setHoveredState(disabledValue) || setClicked(disabledValue);
  const onClick = (index) => setClicked(useIndex ? index : true);

  return { hovered, clicked, onMouseEnter, onMouseLeave, onClick };
};
