import { useTooltipStyle } from "contexts/TooltipStyleContext";
import { Tooltip as ExternalTooltip } from "react-mint";

export const Tooltip = ({
  boxClassName,
  size,
  style,
  boxStyle,
  tailStyle,
  ...rest
}) => {
  const tooltipProps = useTooltipStyle({
    boxClassName,
    size,
    style,
    boxStyle,
    tailStyle,
  });

  return <ExternalTooltip {...tooltipProps} {...rest} />;
};
