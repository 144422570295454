import { createContext, useCallback, useContext, useMemo } from "react";
import { useQueryClient } from "@tanstack/react-query";

const DocumentManagerContext = createContext();

export const MANAGER_PREFIX = "DOCUMENT_MANAGER";

export const DocumentManagerProvider = ({ children }) => {
  const queryClient = useQueryClient();

  const get = useCallback(
    (documentKeyArr) => {
      return queryClient.getQueryData([MANAGER_PREFIX, ...documentKeyArr]);
    },
    [queryClient]
  );

  const set = useCallback(
    (documentKeyArr, updater) => {
      return queryClient.setQueryData(
        [MANAGER_PREFIX, ...documentKeyArr],
        updater
      );
    },
    [queryClient]
  );

  const clear = useCallback(
    (documentKeyArr = []) => {
      return queryClient.resetQueries({
        queryKey: [MANAGER_PREFIX, ...documentKeyArr],
      });
    },
    [queryClient]
  );

  const clearAll = useCallback(() => clear(), [clear]);

  const value = useMemo(
    () => ({
      queryClient,
      get,
      set,
      clear,
      clearAll,
    }),
    [clear, clearAll, queryClient, get, set]
  );

  return (
    <DocumentManagerContext.Provider value={value}>
      {children}
    </DocumentManagerContext.Provider>
  );
};

export const useDocumentManager = () => {
  return useContext(DocumentManagerContext);
};
