import { useSettingsDrawerPortal } from "components/Header/useSettingsDrawerPortal";
import { createContext, useContext, useEffect, useRef, useState } from "react";

const AppHeaderContext = createContext();

export const AppHeaderProvider = ({ children }) => {
  const [refresh, setRefresh] = useState();
  const [isPortalReady, setIsPortalReady] = useState();
  const portalRef = useRef(null);
  const settingsDrawer = useSettingsDrawerPortal();

  return (
    <AppHeaderContext.Provider
      value={{
        refresh,
        setRefresh,
        portalRef,
        isPortalReady,
        setIsPortalReady,
        settingsDrawer,
      }}
    >
      {children}
    </AppHeaderContext.Provider>
  );
};

export const useAppHeader = ({ onRefresh } = {}) => {
  const context = useContext(AppHeaderContext);
  const { setRefresh } = context;

  // update the refresh function when the onRefresh prop changes
  useEffect(() => setRefresh(() => onRefresh), [setRefresh, onRefresh]);

  // on unmount, clear the refresh function
  useEffect(() => {
    return () => {
      setRefresh(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return context;
};
