import styles from "./scaleTransition.module.scss";

export const ScaleTransition = ({ children, isScaled, percent = 110 }) => (
  <div
    className={styles["container"]}
    style={{
      transform: `scale(${isScaled ? percent : 100}%)`,
      transition: "transform 0.1s ease-out",
    }}
  >
    {children}
  </div>
);
