import { createRoute } from "@tanstack/react-router";
import { rootRoute } from "components/Router/routes/rootRoute";
import { gameMasterAppHeaderConfig } from "./gameMasterAppHeaderConfig";

export const gameMasterRoute = createRoute({
  getParentRoute: () => rootRoute,
  ...gameMasterAppHeaderConfig,
}).lazy(() => import("./lazyGameMasterRoutes").then((d) => d.gameMasterRoute));

const specificGameMasterRoute = createRoute({
  getParentRoute: () => gameMasterRoute,
  path: "$pageId",
  staticData: {
    label: "GM",
  },
}).lazy(() =>
  import("./lazyGameMasterRoutes").then((d) => d.specificGameMasterRoute)
);

gameMasterRoute.addChildren([specificGameMasterRoute]);
