import { useDatabase } from "./DatabaseContext";
import { useMainAccessors } from "./useMainAccessors";

const USERS_COLLECTION = "users";
const USERS_PATH = [USERS_COLLECTION];

export const useUsersAccessors = () => {
  const db = useDatabase();
  return useMainAccessors(db, USERS_PATH);
};
