import { Outlet, useLocation, useRouterState } from "@tanstack/react-router";
import { NavigatorProvider } from "../NavigatorContext";
import { AppHeader } from "components/Header/AppHeader";
import { Tutorial } from "components/Tutorial/Tutorial";
import { TooltipProvider } from "contexts/TooltipContext";
import { Footer } from "components/Footer/Footer";
import { useTheme } from "contexts/ThemeContext";
import { useEscapeBlur } from "hooks/listeners/useEscapeBlur";
import { useRef } from "react";
import { usePageConfig } from "hooks/usePageConfig";
import { useForceScrollToTop } from "components/Common/hooks/useForceScrollToTop";
import classnames from "classnames/bind";
import styles from "../../../app.module.scss";
import { Helmet } from "react-helmet";
import { TooltipStyleProvider } from "contexts/TooltipStyleContext";
import { Home } from "components/Home/Home";
import { IFrameProvider } from "contexts/IframeContext";
import { ErrorBoundary } from "react-error-boundary";
import { CrashPage } from "CrashPage";
import { noop } from "lodash";
import { EmptyPageSection } from "components/Common/Section/EmptyPageSection";
import { InitialLoadChangeLog } from "components/ChangeLog/ChangeLog";
import { useAuth } from "contexts/AuthContext";

const classNameBuilder = classnames.bind(styles);

const PendingComponent = () => (
  <EmptyPageSection label={"Please wait a moment..."}>
    Loading Page...
  </EmptyPageSection>
);

const RootContentChildren = () => {
  const state = useRouterState();

  return state.isLoading ? <PendingComponent /> : <Outlet />;
};

const RootContent = ({ contentRef }) => {
  const [appClassName, contentClassName] = usePageConfig([
    "appClassName",
    "contentClassName",
  ]);
  const { shouldSetupUser } = useAuth();

  return (
    <TooltipStyleProvider scrollRef={contentRef}>
      <div
        className={classNameBuilder("scrollable", appClassName)}
        ref={contentRef}
      >
        <div className={classNameBuilder("content", contentClassName)}>
          {!shouldSetupUser && (
            <>
              <Tutorial />
              <InitialLoadChangeLog />
            </>
          )}
          <TooltipProvider className={styles["tooltip-provider"]}>
            <Home>
              <RootContentChildren />
            </Home>
          </TooltipProvider>
        </div>
        <Footer />
      </div>
    </TooltipStyleProvider>
  );
};

export const Root = () => {
  const { themeClassNames, theme } = useTheme();
  useEscapeBlur();
  const location = useLocation();
  const contentRef = useRef();
  useForceScrollToTop({ scrollRef: contentRef, deps: [location?.pathname] });

  return (
    <NavigatorProvider>
      <IFrameProvider>
        <TooltipProvider>
          <ErrorBoundary FallbackComponent={CrashPage} onReset={noop}>
            <div className={classNameBuilder("app", ...themeClassNames)}>
              <Helmet>
                <title>Wildsea Digital Character Sheet</title>
                <link
                  rel="icon"
                  type="image/png"
                  href={`/wildsea-character-sheet/favicon-${theme.secondary}.ico`}
                />
              </Helmet>
              <AppHeader />
              <RootContent contentRef={contentRef} />
            </div>
          </ErrorBoundary>
        </TooltipProvider>
      </IFrameProvider>
    </NavigatorProvider>
  );
};
